import React from 'react';
import { Link } from '@reach/router';

const Navbar = () => {

    return (
    <nav className="navbar navbar-expand-md rex-nav rex-bg-light fixed-top">
        <div className="nav-content container px-0">

            <Link to="/" className="rex-nav-item px-0">
            <img src='/assets/rex-only-aw.png' style={{width: '120px', height: 'auto'}} alt='REX logo'/>
            </Link>

            <button type="button" className="navbar-toggler rex-font-color-do" data-toggle="collapse" data-target="#navbarCollapse">
            <i className="fa fa-bars" aria-hidden="true"></i>
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav ml-auto pt-3">
                <li className="rex-nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link className="nav-link rex-link" to="/">HOME</Link>
                </li>
                <li className="rex-nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link className="nav-link rex-link" to="/pricing">PRICING</Link>
                </li>
                <li className="rex-nav-item dropdown" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <button className="nav-link rex-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Developers
                    </button>
                    <div className="dropdown-menu rex-dropdown" aria-labelledby="navbarDropdown">
                    <Link className="nav-link rex-dropdown-link" to="/docs">Documentation</Link>
                    <a className="nav-link rex-dropdown-link" href='https://app.rexscan.com/api/v1/' target='blank'>API documentation</a>
                    </div>
                </li>
                <li className="rex-nav-item dropdown" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <button className="nav-link rex-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Learn
                    </button>
                    <div className="dropdown-menu rex-dropdown" aria-labelledby="navbarDropdown">
                    <Link className="nav-link rex-dropdown-link" to="/learn">Articles</Link>
                    <Link className="nav-link rex-dropdown-link" to="/blog">Blog posts</Link>
                    </div>
                </li>
                <li className="rex-nav-item dropdown" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <button className="nav-link rex-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Get started
                    </button>
                    <div className="dropdown-menu rex-dropdown" aria-labelledby="navbarDropdown">
                    <Link className="nav-link rex-dropdown-link" to="/learn/rex-web-how-to">How to use REX web application</Link>
                    <div className="dropdown-divider"></div>
                    <a className="nav-link rex-dropdown-link" href="https://app.rexscan.com/register" target='blank'>Sign up</a>
                    </div>
                </li>
                <li className="rex-nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a className="rex-button-mini" href="https://app.rexscan.com/login" target='blank' data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">LOGIN</a>
                </li>
                </ul>
            </div>
        </div>
    
    </nav>
    );
}

export default Navbar;