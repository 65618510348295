import React, { useEffect } from 'react';

const PrivacyNotice = () => {

    useEffect(() => {
        document.title = "REX | Privacy Notice";
    });

    return (
        <div className='container text-left px-4 py-4'>
            <h2 className='pt-5 mt-5'>Privacy Notice</h2>
            <div className='container px-0 py-5 mx-0 my-5'>

                <strong>Where we get your data from</strong>
                <p>
                    We collect data in the following ways:
                </p>
                <p>
                    <strong>When you give it to us directly </strong>
                    – you may have given us your data in order to sign up for a REX test    <strong> </strong>licence, our newsletter, to contact us about our services
                    or to ask us to contribute to an event or conference or engage with us
                    through Social Media.
                </p>
                <p>
                    <strong>
                        When you give permission to other organisations to share it with us
                    </strong>
                    – you may have given<strong> </strong>permission for a company,
                    organisation or individual to share your data us. This could be if you are
                    looking for advice or information on the services we provide or ask us to
                    contribute to an event or conference.
                </p>
                <p align="justify">
                    Dependent on your settings or the privacy policies for social media and
                    messaging services that we use, such as Facebook, Twitter, or LinkedIn, you
                    may give us permission to access information from those accounts or
                    services.
                </p>
                <p>
                    <strong>When it is publicly available </strong>
                    – this will only include data that you have made publicly available    <strong> </strong>through mechanisms such as Companies House or other
                    online directories.
                </p>
                <p>
                    <strong>When you use our website </strong>
                    – We use Google Analytics to track and report our website traffic. The    <strong> </strong>data we receive from Google Analytics is anonymous and
                    cannot be used to identify you.
                </p>
                <p>
                    To opt out of being tracked by Google Analytics across all websites, visit
                    http://tools.google.com/dlpage/gaoptout.
                </p>
                <p>
                    <strong>What we collect about you</strong>
                </p>
                <p>
                    The data we collect about depends on why you have provided it. It will
                    include some or all of the following:
                </p>
                <ul>
                    <li>
                        Company name
                    </li>
                </ul>
                <ul>
                    <li>
                        Company address
                    </li>
                </ul>
                <ul>
                    <li>
                        Contact name
                    </li>
                </ul>
                <ul>
                    <li>
                        Contact email address
                    </li>
                </ul>
                <ul>
                    <li>
                        Contact phone number
                    </li>
                </ul>
                <p>
                    <strong>What we do with it</strong>
                </p>
                <p>
                    We use your data to:
                </p>
                <ul>
                    <li>
                        Set-up and manage your REX testing licence.
                    </li>
                </ul>
                <ul>
                    <li>
                        Communicating with you – we will need you feedback when you use REX
                        as a tester so we will need to ask you questions. We may also
                        engage with you about our ongoing professional relationship,
                        including contractual and performance obligations, and any future
                        initiatives by either party that may affect you and/or us.
                    </li>
                </ul>
                <ul>
                    <li>
                        For Legal purposes – to handle and resolve legal disputes or to
                        enforce the terms of a contract. By sharing other people’s personal
                        data in relation to your business, you are confirming that they
                        have agreed to have their personal data shared with and used by
                        Digital Interruption in accordance with this Statement.
                    </li>
                </ul>
                <p>
                    <strong>Who we share it with</strong>
                </p>
                <p>
                    Our website, email and direct marketing are externally hosted by the
                    following:
                </p>
                <ul>
                    <li>
                        1&amp;1
                    </li>
                </ul>
                <ul>
                    <li>
                        Zoho
                    </li>
                </ul>
                <ul>
                    <li>
                        Slack
                    </li>
                </ul>
                <ul>
                    <li>
                        Hubspot
                    </li>
                </ul>
                <p>
                    <strong>How we keep it secure and up to date</strong>
                </p>
                <p>
                    In accordance with UK and European data protection laws, we take reasonable
                    measures to secure all personal data.
                </p>
                <p>
                    We assign retention periods to all personal data and review it at regular
                    intervals to ensure its ongoing relevance and accuracy.
                </p>
                <p>
                    <strong>Cookies</strong>
                </p>
                <ul>
                    <li>
                        rex (required to allow users to use the “Remember Me” option on the
                        login page)
                    </li>
                </ul>
                <p>
                    <strong>How we uphold you rights</strong>
                </p>
                <p>
                    The General Data Protection Regulation (GDPR) provides the following rights
                    for individuals:
                </p>
                <ul>
                    <li>
                        The right to be informed
                    </li>
                </ul>
                <ul>
                    <li>
                        The right of access
                    </li>
                </ul>
                <ul>
                    <li>
                        The right to rectification
                    </li>
                </ul>
                <ul>
                    <li>
                        The right to erasure
                    </li>
                </ul>
                <ul>
                    <li>
                        The right to restrict processing
                    </li>
                </ul>
                <ul>
                    <li>
                        The right to data portability
                    </li>
                </ul>
                <ul>
                    <li>
                        The right to object
                    </li>
                </ul>
                <ul>
                    <li>
                        Rights in relation to automated decision making and profiling
                    </li>
                </ul>
                <p>
                    You can request any of the above by emailing us at
                    {' '}
                    <a href="mailto:rex@digitalinterruption.com">
                        <u>rex@digitalinterruption.com</u>
                    </a>.
                </p>
                <p>
                    You have the right to lodge a complaint with the Information Commissioners
                    Office (ICO) if you believe we have not adequately upheld these rights.
                </p>
            </div>
        </div>
    );
};

export default PrivacyNotice;
