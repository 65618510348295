import React from 'react';
import { Link } from '@reach/router';
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import BlogPostContent from '../components/BlogPostContent';


const GET_BLOG_TITLES = gql`
  query blogPosts {
    blogPostss(orderBy: createdAt_ASC) {
      title
      createdAt
      id
    }
  }
`;


const BlogPostPage = ({ blog_id }) => {

    return (
        <Query query={GET_BLOG_TITLES}>
            {({ loading, error, data }) => {
                if (loading) return <p className="pt-5">loading yeah</p>;
                if (error || data.blogPosts === null) return <p className="pt-5">error</p>;

                return (
                    <div className='d-flex flex-column align-items-center my-5 px-5 mx-0'>
                        <div className="content row vw-100">
                            <div className="text-left col-sm-2 d-none d-sm-block">
                                <div className="rex-sticky-sidebar">
                                    {data.blogPostss.map((post) => {
                                        return (
                                            <div className="w-100 my-3">
                                                <Link className="text-uppercase" to={`/blog/${post.id}`}>{post.title}</Link>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center container col-sm-10 mw-100">
                                <BlogPostContent blog_id={blog_id} />
                            </div>
                        </div>
                    </div>
                )
            }}
        </Query>
    )
}

export default BlogPostPage;