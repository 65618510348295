import React from 'react';
import ContentBlock from '../components/ContentBlock';

const LearnHome = () => {
    return (
        <div className='py-5 container hpfinal-content'>
            <p className="rex-font-style-main mb-5">REX is here to help you learn! Use the articles below to help you use REX, or learn about building secure mobile applications.</p>
            <div className="rex-border-top">
            <h2 className='pt-5 rex-font-style-title rex-font-color-odb'>USING REX</h2>
            <ContentBlock title="How to use the REX web application" blurb="Using the REX web application to scan your Android application for vulnerabilities" link="/learn/rex-web-how-to" />
            </div>
            <div className="rex-border-top">
            <h2 className='pt-5 rex-font-style-title rex-font-color-odb'>SECURE MOBILE APPLICATION DEVELOPMENT</h2>
            <ContentBlock title="Part 1: Introduction &amp; security grading" blurb="Why is mobile application security so important, and how can vulnerabilities be exploited?" link="/learn/sec-mob-dev/01" />
            <ContentBlock title="Part 2: Transport Layer Security" blurb="How do you ensure the network layer is secure and is therefore not susceptible to eavesdropping?" link="/learn/sec-mob-dev/02" />
            <ContentBlock title="Part 3: Certificate Pinning" blurb="How do you increase security around certificates?" link="/learn/sec-mob-dev/03" />
            <ContentBlock title="Part 4: Data Storage" blurb="What data should and shouldn't be stored on the device?" link="/learn/sec-mob-dev/04" />
            <ContentBlock title="Part 5: Binary Protections" blurb="What are binary protections and how can they increase you application security?" link="/learn/sec-mob-dev/05" />
            <ContentBlock title="Part 6: Obfuscation" blurb="How can you implement obfuscation?" link="sec-mob-dev/06" />
            <ContentBlock title="Part 7: Root/Jailbreak Detection" blurb="How can you prevent attackers taking advantage of weakened security controls?" link="/learn/sec-mob-dev/07" />
            <ContentBlock title="Part 8: Debug Protection" blurb="How do you prevent attackers using a debugger on your application?" link="/learn/sec-mob-dev/08" />
            <ContentBlock title="Part 9: Hook Detection" blurb="How do you stop attackers modifying your applications behaviour at runtime?" link="/learn/sec-mob-dev/09" />
            <ContentBlock title="Part 10: Runtime Integrity Checks" blurb="How can you detect whether your applications have been modified or are executing code that has been injected?" link="/learn/sec-mob-dev/10" />
            <ContentBlock title="Part 11: Attacker Effort &amp; stacking" blurb="How can combining security controls protect your application?" link="/learn/sec-mob-dev/11" />
            </div>
        </div>
    );
}

export default LearnHome;