import React, { useEffect } from 'react';
import RexBanner from '../components/RexBanner';
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import ContentBlock from '../components/ContentBlock';
import Loading from '../components/Loading';
import Error from '../components/Error';


const GET_BLOG_LIST = gql`
  query blogPosts {
    blogPostss(orderBy: createdAt_DESC) {
      title
      blurb
      id
      heroImage {
        url
      }
    }
  }
`;

const BlogMain = () => {

    useEffect(() => {
        document.title = "REX | Blog";
    });

    return (
        <div>
            <RexBanner text="BLOG" subtext="WE HAVE A BLOG NOW"/>
                <div className='py-5 container hpfinal-content'>
                    <p className="rex-font-style-main mb-5">Want to learn more about our journey and the inner workings of our minds? Yes, we thought so.</p>
                    <div className="rex-border-top">
                    <Query query={GET_BLOG_LIST}>
                            {({ loading, error, data }) => {
                                if (loading) return <Loading/>;
                                if (error || data.blogPosts === null) return <Error/>;
                                return (
                                    <div>
                                    {data.blogPostss.map((post) => {
                                        return (
                                            <ContentBlock picture={post.heroImage.url} title={post.title} blurb={post.blurb} link={`/blog/${post.id}`} />
                                        );
                                    })}
                                    </div>
                                )
                            }}
                    </Query>
                </div>
            </div>           
        </div>
    );
};

export default BlogMain;