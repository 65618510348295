import React, { useEffect } from 'react';
import { Link } from '@reach/router';
import RexBanner from '../components/RexBanner';

const FAQs = () => {

        useEffect(() => {
                document.title = "REX | FAQs";
        });

        return (
                <div>

                        <RexBanner text="FAQS" subtext="ALL YOUR REX QUESTIONS ANSWERED" />
                        <div className='d-flex flex-column align-items-center my-5 px-4 mx-0'>
                                <div className="content row">
                                        <div className="text-left col-sm-2 d-none d-sm-block">
                                                <div className="rex-sticky-sidebar">
                                                        <a className="my-1" href='#what-is-rex'>Q. What is REX and what does it do?</a>
                                                        <a className="my-1" href='#rex-stands-for'>Q. What does REX stand for?</a>
                                                        <a className="my-1" href='#rex-for-whom'>Q. Whom is REX for?</a>
                                                        <a className="my-1" href='#vulns'>Q. Will you be able to see the vulnerabilities REX uncovers in my app?</a>
                                                        <a className="my-1" href='#secure'>Q. But this is sensitive information, how do you keep it secure?</a>
                                                        <a className="my-1" href='#how-long'>Q. How long do you keep this information?</a>
                                                </div>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center align-items-start container col-sm-10 article-content">
                                                <p>Find the answers to commonly asked questions about REX below. For more specific questions please refer to the <Link to='/docs'>documentation</Link>, and if your question is still unanswered <Link to='/contact'>contact us.</Link></p>
                                                <div className="anchor" id='what-is-rex' />
                                                <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Q. What is REX and what does it do?</h2>
                                                <p>A. REX is a vulnerability scanner for Android applications. With REX, you can scan your Android apps as they’re being developed to detect security weaknesses early and often. This is a different approach to traditional “penetration testing” where a human will manually look for security issues when development is complete.</p>
                                                <div className="anchor" id='rex-stands-for' />
                                                <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Q. What does REX stand for?</h2>
                                                <p>A. REX doesn't stand for anything, we just really like dinosaurs.</p>
                                                <div className="anchor" id='rex-for-whom' />
                                                <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Q. Whom is REX for?</h2>
                                                <p>A. REX was designed specifically for developers and software testers to help them test the security of android applications.</p>
                                                <div className="anchor" id='vulns' />
                                                <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Q. Will you be able to see the vulnerabilities REX uncovers in my apps?</h2>
                                                <p>A. The short answer is yes. We store all the scan results associated with your account in the backend. This is so you can access them and compare them to track improvements with your security and vulnerability management. Saying that - we don’t look in the backend unless we have to; it’ll only be if there is an issue or for maintenance. So, even though technically we can see your vulns, we won’t really be looking.                    </p>
                                                <div className="anchor" id='secure' />
                                                <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Q. But this is sensitive information, how do you keep it secure?</h2>
                                                <p>A. We regularly pentest REX, we follow our secure coding guidelines, we have stringent internal security processes and we require the hosting services (Google Cloud) to do the same; basically, we practice what we preach.</p>
                                                <div className="anchor" id='how-long' />
                                                <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Q. How long do you keep this information?</h2>
                                                <p>A. For as long as you want us to. If you delete it, it will also be deleted from the backend. If you close your account, it will be deleted from the backend. However, because it’s hosted with Google Cloud, it can take up to ten days for the data to be deleted from backups.</p>
                                        </div>
                                </div>
                        </div>
                </div>
        );
};

export default FAQs;