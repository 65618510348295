import React from 'react';
import CTAButton from './CTAButton';

const HomepageFour = () => {
    return (
        <div>

<div className="d-flex justify-content-center">


        <div className='content container d-flex flex-wrap-reverse justify-content-center align-items-center'>
        <div className='text-left px-5 my-5 half-container'>
            <h2 className="rex-font-color-odb rex-font-style-title rex-sub my-0 rex-skinny-line">INTEGRATE</h2>
            <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black">WITH YOUR TOOLS</h5>
            <p className="rex-font-color-do">Historically security tools are made for security professionals and aren’t designed to fit in to the development workflow. REX is made for developers and software testers and sits in the centre of your pipeline. REX integrates with the tools you already use like Jenkins, so is quicker to use and more effective than an external scanner.</p>
            <CTAButton cta="learn more" to="/docs"/>
        </div>
        <video autoPlay loop muted className="w-100 mx-3 my-5 half-container rex-screenshot mx-3" src="/assets/screenshots/api-vid.mp4" alt="Video demonstrating REX API" />
        </div>
    </div>
</div>
    
    );
}

export default HomepageFour;