import React, { useEffect } from 'react';
import RexBanner from '../components/RexBanner';

const Contact = () => {

    useEffect(() => {
        document.title = "REX | Contact";
    });

    return (
        <div>
            <RexBanner text="CONTACT US" subtext="GET IN TOUCH" />
            <div className='d-flex flex-column align-items-center my-5'>
                <div className="pb-5 pt-3">
                    <p className="text-center mb-5">
                        There's a few ways you can get in touch with us here at REX, have a look below to see what suits you.
                    </p>
                    <div className="text-center">
                        <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-4">Twitter</h2>
                        <p>
                            Find us over on <a href="https://twitter.com/REX_scan" rel="noreferrer" target="_blank">Twitter</a> to read our latest news and send us a message.
                        </p>
                        <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-4">LinkedIn</h2>
                        <p>
                            If you or your business is on <a href="https://www.linkedin.com/company/rexscan/about/" rel="noreferrer" target="_blank">LinkedIn</a> then connect with us there.
                        </p>
                        <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-4">E-mail</h2>
                        <p>
                            You can e-mail us at <a href="mailto:rex@digitalinterruption.com">rex@digitalinterruption.com</a> and we will get back to you as soon as we can.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;

