export const people = {
    "Harriet":{
        title: "Harriet Melesi, Developer"
    },
    "Jahmel":{
        title: "Jahmel Harris, Technical Director"
    },
    "Saskia": {
        title: "Saskia Coplans, Strategic Director"
    },
    "Rob": {
        title: "Rob, Lead Developer"
    },
}