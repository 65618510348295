import { Link } from '@reach/router';
import React from 'react';

const Footer = () => {
    return (
    <footer className="px-2 pt-3 pb-4 rex-bg-dark d-flex w-100">
        <div className="nav-content d-flex container-fluid align-items-end justify-content-around footer-content flex-wrap">
            <div className="d-flex flex-column">
                <Link to="/newsletter" className="rex-link-white">
                    Subscribe
                </Link>
                <Link to='/contact' className="rex-link-white">
                    Contact
                </Link>
                <Link to='/privacy-notice' className="rex-link-white">
                    Privacy Notice
                </Link>
                <Link to='/terms' className="rex-link-white">
                    Terms and Conditions
                </Link>
            </div>
            <div className='d-flex flex-column align-items-center'>
            <div className="py-2">
                <a href='https://twitter.com/REX_Scan' target='blank'>
                    <img className='px-1 footer-icon' src='/assets/tw.png' alt='Twitter logo' />
                </a>
                <a href='https://www.linkedin.com/company/rexscan/about/' target='blank'>
                    <img className='px-1 footer-icon' src='/assets/linkedin.png' alt='Linkedin logo' />
                </a>
            </div>
                <a href='https://www.digitalinterruption.com' target='blank' className='rex-link'>
                    <img className='footer-wide' src='/assets/digital-inter.png' alt='Digital Interruption logo' />
                </a>
            </div>

            <img src='/assets/REX-Evolution.png' className="footer-wide pt-4 w-100" alt='A drawing of early man evolving into a T-Rex' />

        </div>
    </footer>
    );
}

export default Footer;