import React, { useEffect } from 'react';
import { Link } from '@reach/router';
import { Router } from '@reach/router';
import SMD01 from '../content/SMD01';
import SMD02 from '../content/SMD02';
import SMD03 from '../content/SMD03';
import SMD04 from '../content/SMD04';
import SMD05 from '../content/SMD05';
import SMD06 from '../content/SMD06';
import SMD07 from '../content/SMD07';
import SMD08 from '../content/SMD08';
import SMD09 from '../content/SMD09';
import SMD10 from '../content/SMD10';
import SMD11 from '../content/SMD11';

const SecMobDev = () => {

    useEffect(() => {
        document.title = "REX | Building Secure Mobile Applications";
    });

    return (
        <div className="d-flex flex-column align-items-center my-5 px-4 mx-0">
            <div className="row">
                <div className="text-left col-sm-2 d-none d-sm-block">
                    <div className="rex-sticky-sidebar">
                        <div className="w-100 my-3">
                            <Link to="/learn/sec-mob-dev/01">Part 1: Introduction &amp; security grading</Link>
                        </div>
                        <div className="w-100 my-3">
                            <Link to="/learn/sec-mob-dev/02">Part 2: Transport Layer Security</Link>
                        </div>
                        <div className="w-100 my-3">
                            <Link to="/learn/sec-mob-dev/03">Part 3: Certificate Pinning</Link>
                        </div>
                        <div className="w-100 my-3">
                            <Link to="/learn/sec-mob-dev/04">Part 4: Data Storage</Link>
                        </div>
                        <div className="w-100 my-3">
                            <Link to="/learn/sec-mob-dev/05">Part 5: Binary Protections</Link>
                        </div>
                        <div className="w-100 my-3">
                            <Link to="/learn/sec-mob-dev/06">Part 6: Obfuscation</Link>
                        </div>
                        <div className="w-100 my-3">
                            <Link to="/learn/sec-mob-dev/07">Part 7: Root/Jailbreak Detection</Link>
                        </div>
                        <div className="w-100 my-3">
                            <Link to="/learn/sec-mob-dev/08">Part 8: Debug Protection</Link>
                        </div>
                        <div className="w-100 my-3">
                            <Link to="/learn/sec-mob-dev/09">Part 9: Hook Detection</Link>
                        </div>
                        <div className="w-100 my-3">
                            <Link to="/learn/sec-mob-dev/10">Part 10: Runtime Integrity Checks</Link>
                        </div>
                        <div className="w-100 my-3">
                            <Link to="/learn/sec-mob-dev/11">Part 11: Attacker Effort &amp; stacking</Link>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center container col-sm-10 article-content my-5">
                    <Router>
                        <SMD01 path="/01" />
                        <SMD02 path="/02" />
                        <SMD03 path='/03' />
                        <SMD04 path="/04" />
                        <SMD05 path="/05" />
                        <SMD06 path="/06" />
                        <SMD07 path="/07" />
                        <SMD08 path="/08" />
                        <SMD09 path="/09" />
                        <SMD10 path="/10" />
                        <SMD11 path="/11" />
                        <SMD01 path="*" />
                    </Router>
                </div>
            </div>
        </div>
    );
}

export default SecMobDev;