import React from 'react';
import './App.css';
import { Redirect, Router, Location } from '@reach/router';
import Homepage from './screens/Homepage';
import Docs from './screens/Docs';
import Navbar from './components/Navbar';
import FAQs from './screens/FAQs';
import Footer from './components/Footer';
import Contact from './screens/Contact';
import Terms from './screens/Terms';
import Newsletter from './screens/Newsletter';
import PrivacyNotice from './screens/PrivacyNotice';
import Content from './screens/Content';
import BlogMain from './screens/BlogMain';
import BlogPostPage from './screens/BlogPostPage';
import PricePage from './screens/PricePage';

const App = () => {

  class OnRouteChangeWorker extends React.Component{
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname){
        this.props.action()
      }
    }

    render() {
      return null
    }
  }

  const OnRouteChange = ({ action }) => (
    <Location>
      {({ location }) => <OnRouteChangeWorker location={location} action={action} />}
    </Location>
  )

  return (
    <div className={`App d-flex flex-column justify-content-between align-items-center`}>
      <div className="w-100">
      <Navbar/>
      <Router primary={false}className='main-content'>
        <Homepage path='/'/>
        <Docs path='/docs'/>
        <FAQs path='/faqs'/>
        <Contact path='/contact' />
        <Terms path='/terms' />
        <Content path='/learn/*' />
        <Newsletter path='/newsletter'/>
        <PrivacyNotice path='/privacy-notice' />
        <PricePage path="/pricing" />
        <BlogPostPage path="/blog/:blog_id"/>
        <BlogMain path="/blog"/>
        
        <Redirect from="/blog/release/01" to="/blog/ckoycrg5c2rxi0b52nghm4w06"/>
        <Redirect from="/blog/release/02" to="/blog/ckoyc0w4o2df20b52pu4416sc"/>
        <Redirect from="/blog/release/03" to="/blog/ckoyc4ij42lqa0c08lnv7uwxs"/>
        <Redirect from="/blog/release/04" to="/blog/ckoyc7u4o04mt0d61tsazjd7g"/>
        <Redirect from="/blog/release/05" to="/blog/ckoycrg5c2rxi0b52nghm4w06"/>
        <Redirect from="/blog/release/06" to="/blog/ckoyctm2w2sr20b520qgvlnku"/>
        <Redirect from="/blog/release/07" to="/blog/ckoycv2js0gaz0d61irgoaor5"/>
        <Redirect from="/blog/release/08" to="/blog/ckoycxzhk2ua80b52fl3mt0ho"/>
        <Redirect from="/blog/release/09" to="/blog/ckoyd000o339u0b537jmptnd0"/>
        
        <Redirect from='*' to='/' noThrow/>
      </Router>
      <OnRouteChange action={()=> {window.scrollTo(0,0)}} />
      </div>
      <Footer />
    </div>
  );
}

export default App;
