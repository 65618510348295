import React from 'react';
import { Link } from '@reach/router';

const SMD10 = () => {
    return (
        <div>
            <div className="d-flex align-items-end flex-wrap mb-5">
                <img className="mx-5 blog-person" src="/assets/people/Jahmel.png" alt="8 bit drawing of Jay" />
                <div className="mt-5">
                    <h3 className="rex-font-color-odb rex-font-style-title rex-sub text-uppercase">
                        Secure Mobile Application <br />Development
                    </h3>
                    <h5 className="rex-font-color-do rex-font-main-black text-uppercase">
                        Part 10: Runtime Integrity Checks
                    </h5>
                    <p className="font-italic mb-0">Jahmel Harris, Technical Director</p>
                </div>
            </div>
            <p className="mt-5">
                Detecting whether our applications have been modified or are executing code that has been injected into application important for two reasons; the first is to protect users from trojaned or backdoored versions of our applications. Often, an attacker will add malicious code to popular apps and redistribute them online. We can prevent this by implementing runtime integrity checks.
            </p>

            <p>
                The second (and arguably) more useful reason to implement integrity checks this is to protect the client side security controls we have implemented. As it is fairly trivial to modify a mobile application, any control we implement has the potential of being removed prior to analysis from a malicious actor.                </p>
            <p>
                Because of this, where possible we should not rely on security checks that occur client side. Where this is a requirement (such as with some DRM), the integrity of the application should be confirmed.                 </p>
            <p className="mt-5">
                The following techniques should be considered for integrity checks:
            </p>
            <ul className="text-left">
                <li>
                    File Checksums - The application should compare the checksums of files (including libraries) used with values known by the application. If the checksums do not match, the application should close.
                </li>
                <li>
                    Code Checksums - The application should compare the checksum functions loaded in memory. If these checksums do not match, the application should close.
                </li>
                <li>
                    Consider commercial libraries such as guardIT and ensureIT by Arxan.
                </li>
            </ul>
            <h6 className="rex-font-main-black mt-5">Android</h6>
            <p className="mt-5">
                Android applications can have some level of tamper detection by checking whether the package name is as expected and the application has been signed by the correct developer certificate. This will stop the most common case of application tampering - modifying and resigning the APK. Sample code can be found <a href="https://gist.github.com/scottyab/b849701972d57cf9562e" rel="noreferrer" target="_blank">here</a>.                </p>

            <div className="mt-5 w-100 d-flex justify-content-between">
                <Link to="/learn/sec-mob-dev/09">{'<'} Part 9: Hook Detection</Link>
                <Link to="/learn/sec-mob-dev/11">Part 11: Attacker Effort &amp; stacking{'>'}</Link>
            </div>
        </div>
    );
}

export default SMD10;