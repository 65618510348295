import React from 'react';

const RexBanner = ({text, subtext}) => {
    return (
    <div className="rex-bg-light d-flex justify-content-center">

            <div className='d-flex flex-row w-100 flex-wrap-reverse align-items-start content'>
                <div className="half-container-small">
                <img src='/assets/rex-head-only-aw.png' className="rex-hero-image-small" alt='REX logo'/>
                </div>
                <div className='text-left rex-banner-text half-container-fixed-small pt-3 pb-0 px-5'>
                    <h1 className="rex-font-style-title rex-largest rex-font-color-white rex-skinny-line my-0">{text}</h1>
                    <h5 className="rex-font-style-main rex-font-main-black rex-font-color-do">{subtext}</h5>
                </div>
            </div>

    </div>
    );
}

export default RexBanner;