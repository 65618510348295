import React from 'react';
import { Link } from '@reach/router';
import { Bar } from 'react-chartjs-2';

const SMD11 = () => {

    const stackedData = {
        labels: ['Effort'],
        datasets: [
            {
                label: 'Obfuscation',
                backgroundColor: '#ccffcc',
                borderColor: '#009100',
                borderWidth: 1,
                hoverBackgroundColor: '#ccffcc',
                hoverBorderColor: '#009100',
                data: [4]
            },
            {
                label: 'Root Detection',
                backgroundColor: '#fcddda',
                borderColor: '#ED230D',
                borderWidth: 1,
                hoverBackgroundColor: '#fcddda',
                hoverBorderColor: '#ED230D',
                data: [3]
            },
            {
                label: 'Debug Detection',
                backgroundColor: '#ffe7ff',
                borderColor: '#ff81ff',
                borderWidth: 1,
                hoverBackgroundColor: '#ffe7ff',
                hoverBorderColor: '#ff81ff',
                data: [4]
            },
            {
                label: 'Hooking Detection',
                backgroundColor: '#Ffffcb',
                borderColor: '#F2C64C',
                borderWidth: 1,
                hoverBackgroundColor: '#Ffffcb',
                hoverBorderColor: '#F2C64C',
                data: [5]
            },
            {
                label: 'Integrity Checking',
                backgroundColor: '#ffd6ff',
                borderColor: '#cc3dcc',
                borderWidth: 1,
                hoverBackgroundColor: '#ffd6ff',
                hoverBorderColor: '#cc3dcc',
                data: [5]
            }
        ]
    };

    const data = {
        labels: ['Reverse Engineering', 'Rooting Device', 'Debugging Application', 'Hooking Application', 'Modifying Application'],
        datasets: [
            {
                label: 'No Security Control',
                backgroundColor: '#fcddda',
                borderColor: '#ED230D',
                borderWidth: 1,
                hoverBackgroundColor: '#fcddda',
                hoverBorderColor: '#ED230D',
                data: [1, 1, 3, 4, 4]
            },
            {
                label: 'With Security Control',
                backgroundColor: '#c5ffc5',
                borderColor: '#008100',
                borderWidth: 1,
                hoverBackgroundColor: '#c5ffc5',
                hoverBorderColor: '#008100',
                data: [4, 3, 4, 5, 5]
            }
        ]
    };

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex align-items-end flex-wrap mb-5">
                <img className="mx-5 blog-person" src="/assets/people/Jahmel.png" alt="8 bit drawing of Jay" />
                <div className="mt-5">
                    <h3 className="rex-font-color-odb rex-font-style-title rex-sub text-uppercase">
                        Secure Mobile Application <br />Development
                    </h3>
                    <h5 className="rex-font-color-do rex-font-main-black text-uppercase">
                        Part 11: Attacker Effort &amp; stacking.
                    </h5>
                    <p className="font-italic mb-0">Jahmel Harris, Technical Director</p>
                </div>
            </div>
            <p>
                Like all client side security controls, binary protections can be bypassed by a skilled attacker. By using several of these controls together, however, it is possible to increase the effort needed to a level to deter all but the most skilled attacker with time to attack each control.            </p>
            <p>
                Whilst each control can be bypassed in a short amount of time, each control can help to protect the other. In the following graphs, we should the amount of "effort" required to bypass a control (this is based on input from experienced mobile application hackers).
            </p>
            <div className="w-75 d-flex flex-column align-items-center justify-content-center mt-5">
                <Bar
                    data={data}
                    width={50}
                    height={60}
                    options={{
                        maintainAspectRatio: true,
                        scales: {
                            xAxes: [{
                                scaleLabel: {
                                    display: true,
                                },
                                ticks: {
                                    minRotation: 50,
                                },
                                gridLines: {
                                    drawOnChartArea: false,
                                },
                            }],
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 1,
                                },
                                gridLines: {
                                    drawOnChartArea: false,
                                },
                            }],
                        }
                    }}
                />
                <p className="font-italic mb-5">
                    Figure 3 - Attacker Effort (Individual Controls)
                </p>
                <Bar
                    data={stackedData}
                    width={75}
                    height={100}
                    options={{
                        maintainAspectRatio: true,
                        scales: {
                            xAxes: [{
                                stacked: true,
                                scaleLabel: {
                                    display: true,
                                },
                                ticks: {
                                    maxRotation: 0,
                                },
                                gridLines: {
                                    drawOnChartArea: false,
                                },
                            }],
                            yAxes: [{
                                stacked: true,
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 5,
                                },
                                gridLines: {
                                    drawOnChartArea: false,
                                },
                            }],
                        }
                    }}
                />
                <p className="font-italic">
                    Figure 4 - Attacker Effort (Stacked)
                </p>
            </div>
            <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black mt-5">Grading Applications</h5>
            <p className="mb-5">
                The following can be used to grade your application in each area to determine whether it is inline with high, medium or low security controls. These gradings are examples and should be expanded for specific organisations and applications.
            </p>
            <table className="table table-striped text-left resp_grading">
                <thead>
                    <tr>
                        <td />
                        <th scope="col">High</th>
                        <th scope="col">Medium</th>
                        <th scope="col">Low</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Communication</th>
                        <td>Certificate Pinning is implemented and nothing traverses a cleartext channel</td>
                        <td>All traffic is sent over TLS</td>
                        <td>Traffic is sent in cleartext or TLS/SSL has been weakened for development</td>
                    </tr>
                    <tr>
                        <th scope="row">Data Storage</th>
                        <td>Nothing sensitive stored in the client</td>
                        <td>Data is encrypted with a PIN/passcode</td>
                        <td>Sensitive data is stored within the application sandbox or removable storage</td>
                    </tr>
                    <tr>
                        <th scope="row">Binary Protection</th>
                        <td>Custom protections is use along with those provided by off the shelf software</td>
                        <td>Binary protections enabled with the use of off the shelf software</td>
                        <td>No binary protections present</td>
                    </tr>
                </tbody>
            </table>
            <p className="mt-5">
                Although applications should aspire to be graded high, in reality not all applications would need this level of security.
            </p>

            <div className="mt-5 w-100 d-flex justify-content-between">
                <Link to="/learn/sec-mob-dev/10">{'<'} Part 10: Runtime Integrity Checks</Link>
            </div>
        </div>
    );
}

export default SMD11;