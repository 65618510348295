import React from 'react';
import CTAButton from './CTAButton';

const HomepageSeven = () => {
    return (
        <div className="d-flex justify-content-center rex-bg-second align-items-stretch">
        <div className="d-flex flex-column justify-content-between w-100 flex-shrink-1">
            <div className="top-step-filler"/>
            <div className="bottom-step-filler"/>
        </div>
        <div className="outer-content w-100">
            <div className="w-100 d-flex align-items-start">
                <div className="middle-top-step-filler flex-grow-1"/>
                <div className="skinny-step-rtl-small"/>
            </div>
            <div className="d-flex justify-content-center py-0">
        <div className='content container d-flex flex-wrap justify-content-center align-items-center py-3'>
            <img className="half-container w-100" src="assets/Graph.png" alt="Graph showing difference in cost of fixing vulnerabilities at different stages of software development."/>
        <div className='text-left px-5 my-5 half-container'>
            <h2 className="rex-font-color-offwhite rex-font-style-title rex-sub my-0 rex-skinny-line">SAVE MONEY</h2>
            <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black">SCAN EARLY &amp; OFTEN</h5>
            <p className="rex-font-color-do">It’s more expensive to fix bugs and vulnerabilities in production; 30 times more for bugs, and 100 times more for vulnerabilities. To get the best from your budget use REX to uncover weaknesses before they become a problem saving you time and money, releasing you to innovate and create.</p>
            <CTAButton cta="sign up now" externalLink to="https://app.rexscan.com/register"/>
        </div>
        </div>
        </div>   
            <div className="skinny-step-ltr-small w-100"/>
        </div> 
        <div className="d-flex flex-column justify-content-between w-100 flex-shrink-1">
            <div className="top-step-filler"/>
            <div className="bottom-step-filler"/>
        </div>
    </div>

    );
}

export default HomepageSeven;