import React from 'react';
import { Link } from '@reach/router';

const SMD01 = () => {
    return (
        <div>
            <div className="d-flex align-items-end flex-wrap mb-5">
                <img className="mx-5 blog-person" src="/assets/people/Jahmel.png" alt="8 bit drawing of Jay" />
                <div className="mt-5">
                    <h3 className="rex-font-color-odb rex-font-style-title rex-sub text-uppercase">
                        Secure Mobile Application <br />Development
                    </h3>
                    <h5 className="rex-font-color-do rex-font-main-black text-uppercase">
                        Part 1: Introduction &amp; security grading.
                    </h5>
                    <p className="font-italic mb-0">Jahmel Harris, Technical Director</p>
                </div>
            </div>
            <p>
                In order to reduce cost, is important to build applications where security is considered from the beginning of the development. This allows bugs to be found and fixed sooner as well as discover costly design mistakes which may warrant a complete application rewrite. Applications can be graded based on how much security has been built into the solution however the security controls should be appropriate for the type of application being developed.
            </p>
            <p className="mb-5">
                A table showing an example grading can be seen below. This should be modified to fit your specific organisational needs.
            </p>
            <table className="table table-striped text-left">
                <thead>
                    <tr>
                        <th scope="col">Security Grading</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Low</th>
                        <td>Security has not been considered during development. The application may contain common vulnerability and security testing has not been performed.</td>
                    </tr>
                    <tr>
                        <th scope="row">Medium</th>
                        <td>Security has been considered and security controls have been implemented to protect against common vulnerabilities. Secure coding techniques have been followed and a penetration test has been performed.</td>
                    </tr>
                    <tr>
                        <th scope="row">High</th>
                        <td>Security controls have been implemented and security best practice has been followed. Defence in depth techniques have been employed (such as binary protection) and security test cases have been written and integrated into a build system. Security tests are performed often and the risk associated with the application is known. 	</td>
                    </tr>
                </tbody>
            </table>
            <p className="mt-5">
                Although applications should aspire to be graded high, in reality not all applications would need this level of security.
            </p>
            <p>
                When thinking about mobile security, three attack scenarios should be considered and the application should make attempts to protect against each. Of course, be appropriate for the risk profile of the application. For all scenarios, the reputational risk could be as severe (or even more so) than risk to users and so should be considered when deciding on appropriate security controls.
            </p>
            <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black mt-5">Scenario 1 - Lost or Stolen Device.</h5>
            <p>
                In this attack scenario, think about the impact to the user should their device be lost or stolen. This boils down to what can be done to the device when physical access is granted. Note that the use of a device lock screen should not be assumed.
            </p>
            <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black mt-5">Scenario 2 – Malicious Software and Remote Attacks.</h5>
            <p>
                It is important to consider what could happen if a user inadvertently installs malicious applications on their device. Although the Operating System should provide some protections, any malware running with elevated (possibly root) privileges will be able to bypass these controls. In addition, vulnerabilities may exist within the Operating System which could allow malicious applications to bypass these security controls without higher privileges.
            </p>
            <p>
                Most mobile Operating Systems provide a way for applications to share information. Some are explicitly enabled by the application developer (such as IPC endpoints in Android), whereas others are enabled automatically (e.g. the clipboard for copy/paste functionality). These should be protected appropriately so it cannot be exploited by malware.
            </p>
            <p>
                This category of attack also includes intercepting and/or modification of communications such as network traffic. This can be particularly dangerous should a vulnerability exist in the application or Operating System that can be exploited by malicious traffic or if sensitive information is sent over unencrypted channels.
            </p>
            <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black mt-5">Scenario 3 – Reverse Engineering.</h5>
            <p>
                The final attack scenario is related to the ability for an attacker to use their own application to discover vulnerabilities that can be used to attack other users or to attack the server side applications and infrastructure. Although it is often taught that security through obscurity is not a valid technique in securing applications, it should be used as a defence in depth approach – i.e. one additional layer of security. Slowing an attacker down does not remove vulnerabilities from the application, but it can give additional time needed to discover and patch bugs that have been discovered after deployment.
            </p>
            <p>
                It is important to realise that this type of security control is important and applied to every release of the application as a single release without these controls can give an attacker enough information to look for additional vulnerabilities.
            </p>
            <p>
                It is unfortunate to say, but in recent years the motivation of attackers have changed and nowadays pure curiosity is seldom the reason mobile applications are attacked. Where criminal activity is involved, many attackers will look for the low hanging fruit. By increasing the time and effort needed to perform reconnaissance on an application, an attacker will often give up in favour of an easier target.
            </p>
            <div className="mt-5 w-100 d-flex justify-content-between">
                <div></div>
                <Link to="/learn/sec-mob-dev/02">Part 2: Transport Layer Security {'>'}</Link>
            </div>
        </div>
    );
}

export default SMD01;