import React from 'react';
import CTAButton from './CTAButton';

const HomepageThree = () => {
    return (
    <div className="d-flex justify-content-center rex-bg-second align-items-stretch">
        <div className="d-flex flex-column justify-content-between w-100 flex-shrink-1">
            <div className="top-step-filler"/>
            <div className="bottom-step-filler"/>
        </div>
        <div className="outer-content w-100">
            <div className="w-100 d-flex align-items-start">
                <div className="middle-top-step-filler flex-grow-1"/>
                <div className="skinny-step-rtl-small"/>
            </div>
            <div className="d-flex justify-content-center py-0">
                <div className='content container d-flex flex-wrap justify-content-center align-items-center py-3'>
                    <img className="half-container w-100 rex-screenshot-dark mx-3" src="assets/screenshots/issuescreen.png" alt="Graph using dinosaurs to show difference in cost of fixing vulnerabilities at different stages of software development."/>
                    <div className='text-left px-5 my-5 half-container'>
                        <h2 className="rex-font-color-offwhite rex-font-style-title rex-sub my-0 rex-skinny-line">SECURITY BY DESIGN</h2>
                        <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black">IMPROVE YOUR SKILLS</h5>
                        <p className="rex-font-color-do">Using examples of vulnerable and secure code, with detailed explanations, REX helps developers improve their secure coding skills and teaches testers how to spot security issues in applications.</p>
                        <CTAButton cta="sign up now" externalLink to="https://app.rexscan.com/register"/>
                    </div>
                </div>
            </div>   
            <div className="skinny-step-ltr-small w-100"/>
        </div> 
        <div className="d-flex flex-column justify-content-between w-100 flex-shrink-1">
            <div className="top-step-filler"/>
            <div className="bottom-step-filler"/>
        </div>
    </div>
    );
}

export default HomepageThree;