import React, { useEffect } from 'react';
import HomeBanner from '../components/HomeBanner';
import HomepageEight from '../components/HomepageEight';
import HomepageFour from '../components/HomepageFour';
import HomepageThree from '../components/HomepageThree';
import HomepageTwo from '../components/HomepageTwo';
import HomepageFive from '../components/HomepageFive';
import HomepageSix from '../components/HomepageSix';
import HomepageSeven from '../components/HomepageSeven';
import HomepageNine from '../components/HomepageNine';

const Homepage = () => {

    useEffect(() => {
        document.title = "REX | Home";
});

    return (
    <div className='text-center'>
        <HomeBanner />
        <HomepageTwo />
        <HomepageThree />
        <HomepageFour />
        <HomepageFive />
        <HomepageSix />
        <HomepageSeven />
        <HomepageEight />
      
    </div>
    );
};

export default Homepage;