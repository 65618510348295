import React, { useEffect } from 'react';
import RexBanner from '../components/RexBanner';

const SignUp = () => {

    useEffect(() => {
        document.title = "REX | Newsletter";
    });

    return (
        <div>
            <RexBanner text='NEWS' subtext='ENTER YOUR DETAILS TO SIGN UP TO OUR NEWSLETTER' />
            <div className="rex-bg-white d-flex justify-content-center h-100">
                <div className='d-flex w-100 h-100 flex-wrap-reverse justify-content-center content my-4'>
                    <iframe height='1300px' width='900px' className='border-0' src="https://share.hsforms.com/1lL5fBq7gQLifN--9Uq8oXw4tnut" title="REX newsletter sign up form"></iframe>
                </div>
            </div>
        </div>
    );
};

export default SignUp;