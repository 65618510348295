import React from 'react';
import { Link } from '@reach/router';

const SMD08 = () => {
    return (
        <div>
            <div className="d-flex align-items-end flex-wrap mb-5">
                <img className="mx-5 blog-person" src="/assets/people/Jahmel.png" alt="8 bit drawing of Jay" />
                <div className="mt-5">
                    <h3 className="rex-font-color-odb rex-font-style-title rex-sub text-uppercase">
                        Secure Mobile Application <br />Development
                    </h3>
                    <h5 className="rex-font-color-do rex-font-main-black text-uppercase">
                        Part 8: Debug Protection
                    </h5>
                    <p className="font-italic mb-0">Jahmel Harris, Technical Director</p>
                </div>
            </div>
            <p className="mt-5">
                An easy way for an attacker to understand and modify the application is by attaching a debugger. Like with many binary protection techniques, it should be accepted that with enough time and effort and attacker will be able to bypass these client side controls. Depending on the sensitivity of the data used in the application, or the operations it performs, it may be a requirement to implement novel and unique approaches to debug detection to stay ahead of a malicious attacker.
            </p>
            <h6 className="rex-font-main-black mt-5">iOS</h6>
            <p>
                The most common solution to detect the presence of a debugger is to make an assembly call to the <text is="webview" className="rex-article-code">ptrace()</text> system call with the <text is="webview" className="rex-article-code">PT_DENY_ATTACH</text> argument. As the call is being made directly in assembly, an attacker cannot simple hook the ptrace wrapper function. In order to increase the difficulty further, the syscall should be scattered inline though out the code base. This has the effect of increasing the number of places an attacker would need to discover and remove the debug protection.                </p>
            <p>
                A second approach that should be employed is viewing the <text is="webview" className="rex-article-code">kp_proc.p_flag</text> flag returned by a call to <text is="webview" className="rex-article-code">sysctl()</text> which indicates the presence of a debugger. If this flag is '-1', the application should exit. Sample code can be found <a href="https://labs.nettitude.com/blog/ios-and-android-runtime-and-anti-debugging-protections/" rel="noreferrer" target="_blank">here</a>.
            </p>
            <h6 className="rex-font-main-black mt-5">Android</h6>
            <p>
                Although Android will no longer allow any application distributed with Google Play to have the <text is="webview" className="rex-article-code">debuggable</text> flag set to true in the Android Manifest, if an application is distributed in another way (such as direct download) care should be taken to make sure this flag is not set. This can protect users from having their instances of the application debugged whilst in use to allow an attacker to either enter the application sandbox or dump application secrets.                </p>
            <p>
                As an attacker though, it is possible to download an application and add the <text is="webview" className="rex-article-code">debuggable</text> flag back into the AndroidManifest file, or hook the application to have it read this value as true.
            </p>
            <p>
                To protect against this, we should periodically check whether the application is being debugged as well as before any particularly sensitive operations. This can be done by checking whether <text is="webview" className="rex-article-code">FLAG_DEBUGGABLE</text> is set for our application in the <text is="webview" className="rex-article-code">ApplicationInfo</text> class and by checking <text is="webview" className="rex-article-code">isDebuggerConnected</text> and <text is="webview" className="rex-article-code">System.Diagnostics.Debugger.IsAttached</text>. See <a href="https://labs.nettitude.com/blog/ios-and-android-runtime-and-anti-debugging-protections/" rel="noreferrer" target="_blank">here</a> for sample code.
            </p>
            <div className="mt-5 w-100 d-flex justify-content-between">
                <Link to="/learn/sec-mob-dev/07">{'<'} Part 7: Root/Jailbreak Detection</Link>
                <Link to="/learn/sec-mob-dev/09">Part 9: Hook Detection {'>'}</Link>
            </div>
        </div>
    );
}

export default SMD08;