import React from 'react';
import { Link } from '@reach/router';

const SMD04 = () => {
    return (
        <div>
            <div className="d-flex align-items-end flex-wrap mb-5">
                <img className="mx-5 blog-person" src="/assets/people/Jahmel.png" alt="8 bit drawing of Jay" />
                <div className="mt-5">
                    <h3 className="rex-font-color-odb rex-font-style-title rex-sub text-uppercase">
                        Secure Mobile Application <br />Development
                    </h3>
                    <h5 className="rex-font-color-do rex-font-main-black text-uppercase">
                        Part 4: Data Storage
                    </h5>
                    <p className="font-italic mb-0">Jahmel Harris, Technical Director</p>
                </div>
            </div>
            <p className="font-italic mt-5">Attack Scenario: 1, 2, 3</p>
            <div className="text-wrap text-break">
                <p>
                    Thought should be put into what sensitive data is stored on the device as this may be available to an attacker. Ideally, sensitive information (including passwords, encryption keys, API keys, credit card details etc) should either be stored and received from the server or entered by the user when required. This way, should an attacker be able to view the data stored by the application, nothing of value will be gained. It should be noted that techniques exist to recover application data on both rooted and non rooted devices.
                </p>
                <p>
                    Protecting all sensitive data is often a difficult task as the device may log or cache various bits of  information in ways out of our control or in ways we did not anticipate. In order to make sure everything is considered, an expert should provide a checklist of things to disable.
                </p>
                <p>
                    Often, sensitive information relating not to the user, but to the organisation or application developers are available to an attacker once all files are extracted from the IPA (iOS) or APK (android). As these file types are essentially nothing more than a ZIP file, artefacts from the build are often left inside or sensitive information is stored within plaintext files such as the AndroidManifest or plist files. Development URLs, private keys, passwords or even the names and email addresses of developers are often key bits of information an attacker can use and should not be stored within the installation package.
                </p>
                <p>
                    Occasionally an application will need access to an API key to use a web service. When these keys are sensitive and should not be leaked, consider creating a proxy service to access the web service. With this approach, the API key is only available on the proxy service and the normal authentication techniques we use can be used to authenticate the user/mobile application to the proxy.
                </p>
                <p>
                    Data read from the device should never be considered trusted. If a security decision is made on the contents of a file, the integrity of the file should be considered. This can be achieved by using Hashed Messaged Authentication Codes (HMAC) although unless appropriate binary protections are applied, it may be possible for an attacker to modify the binary to accept incorrect values.
                </p>
                <p className="mt-5">
                    Generally, the following should be considered when thinking about whether the data should be stored on the device:
                </p>
                <ul className="text-left">
                    <li>
                        Is the data sensitive?
                        <ul>
                            <li>
                                Don't store it and instead consider retrieving it from a web service protected by proper authentication.
                            </li>
                        </ul>
                    </li>
                    <li>
                        If data is to be stored on the device, encrypt it with a key generated from some user input (such as a passcode or PIN or with Touch ID in iOS 9 and later). The PIN should not be stored.
                        <ul>
                            <li>
                                As this may be susceptible to an offline brute-force, there should be no way for an attacker to know whether the data decrypted correctly or not.
                            </li>
                            <li>
                                Strong encryption functions should be used.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Data should not be trusted. It should be assumed it can be modified at any time.
                    </li>
                    <li>
                        Don't trust the security of the device. If the device is rooted, all security controls may fail. If a security control has a vulnerability, an attacker may be able to bypass it to access sensitive information.
                    </li>
                    <li>
                        Understand the platform - data can be logged without our knowledge.
                    </li>
                    <li>
                        Logs can be read by someone with physical access to the device or remotely given the right privileges.
                    </li>
                </ul>
                <h6 className="rex-font-main-black mt-5">iOS</h6>
                <p className="mt-5 font-italic">
                    Screenshots
                </p>
                <p>
                    In order to improve perceived performance, iOS will screenshot an application as it goes into the background (stored in the applications <text is="webview" className="rex-article-code">Library/Caches/Snapshots/{'<'}Bundle ID{'>'}</text> directory). When the application is in the task switcher or relaunches, iOS will display the screenshot. When launching, from a user's point of view the application is ready to use immediately. In reality, until the application finishes loading the Operating System is only displaying the last image of the application as it was put into the backgrounded state.
                </p>
                <p className="mt-5">
                    This is something to be aware of as any application that displays sensitive information (such as banking details, user tokens or encryption keys) can inadvertently leak that data by this process.
                    These are the recommended ways to address this issue:
                </p>
                <ul className="text-left">
                    <li>
                        Don't display anything sensitive on screen. If this is not possible, sensitive information should be obscured by the application (e.g. by asterisks)
                    </li>
                    <li>
                        If sensitive data should be on screen, make sure it is obscured by an image or by setting the Hidden attribute on the control. This can be achieved displaying a splash screen in the <text is="webview" className="rex-article-code">applicationDidEnterBackground</text> function as this function is called when an application is about to go into the background. This should be reversed in <text is="webview" className="rex-article-code">applicationWillEnterForeground</text>. Sample code can be found <a href="https://developer.apple.com/library/content/qa/qa1838/_index.html" rel="noreferrer" target="_blank">here</a>.
                    </li>
                </ul>
                <p className="mt-5 font-italic">
                    Web Cache
                </p>
                <p>
                    As many apps use web services or embed web controls, it's likely sensitive information will be cached. Often, this can include passwords, credit card details, session tokens and more. Data can be cached in several locations and so care should be taken to make sure caching is disabled or cached data is removed.
                </p>
                <p>
                    iOS provides many ways of specifying the cache should not be used (including specifying the <text is="webview" className="rex-article-code">Cache-Control: no-cache</text> HTTP Header) but many approaches can and will be ignored by iOS under specific edge cases and so should not be relied upon. As well as disabling caching by setting <text is="webview" className="rex-article-code">setDiskCapacity</text> and <text is="webview" className="rex-article-code">setMemoryCapacity</text> to 0 in <text is="webview" className="rex-article-code">applicationDidFinishLaunch</text> the Cache directory should be manually deleted. <text is="webview" className="rex-article-code">NSCachesDirectory</text> can be used to get the cache directory.
                </p>
                <p className="mt-5 font-italic">
                    Local Storage
                </p>
                <p>
                    Local storage is an HTML5 mechanism that is used to store offline data for use within a web based application. As this is present within the applications cache directory (<text is="webview" className="rex-article-code">/Library/Caches/*.localstorage</text>). As anything stored in the HTML5 local storage database could be read by an attacker with either physical access or malware running on the target device, care should be taken to store nothing sensitive within the local storage database.
                </p>
                <p>
                    If this cannot be done and there is a requirement to store sensitive data for the user to access offline, risk can be lowered by storing the data in a newly created database encrypted using the Data Protection API. This will encrypt the file based on a the users PIN and will grant access to the encrypted file in different scenarios depending on the protection level used.
                </p>
                <p className="mt-5 font-italic">
                    User Preferences
                </p>
                <p>
                    The <text is="webview" className="rex-article-code">NSUserDefaults</text> class can be used to store data related to the user's preferences related to the application such as how often a document is saved or how information should be displayed. As data is not encrypted (outside of the standard iOS encryption) this data would be available to an attacker if the application is backed up (including in iCloud) or if running the application on a jailbroken device. Because of this, nothing sensitive should be stored using the <text is="webview" className="rex-article-code">NSUserDefaults</text> class. Instead, the keychain or encrypted database should be considered should sensitive data need to be stored locally.
                </p>
                <p className="mt-5 font-italic">
                    Keychain
                </p>
                <p>
                    The keychain is an encrypted database meant for storing sensitive data such as passwords, session tokens and other small pieces of information that should only be available to our application. Although the encryption used to secure the keychain is strong, it is possible to read the keychain on a jailbroken device.
                </p>
                <p className="mt-5">
                    When storing data in the keychain, care should be taken to make sure the data is protected with at the appropriate level. There are several options available, listed here from most secure to least:
                </p>
                <ul className="text-left">
                    <li>
                        kSecAttrAccessibleWhenUnlocked - Available when the device is unlocked.
                    </li>
                    <li>
                        kSecAttrAccessibleAfterFirstUnlock - Available after the passcode is first used after after a reboot.
                    </li>
                    <li>
                        kSecAttrAccessibleAlways - Available when device is locked.
                    </li>
                    <li>
                        kSecAttrAccessibleWhenPasscodeSetThisDeviceOnly - Available when the device is unlocked but only if a passcode is set. Should the passcode be removed, the item will be removed from the keychain.
                    </li>
                </ul>
                <p className="mt-5 font-italic">
                    Data Protection API
                </p>
                <p>
                    When new files are created, they should be protected with the Data Protection API. This will encrypt the file based on a the user's PIN and will grant access to the encrypted file in different scenarios depending on the protection level used.                </p>
                <p className="mt-5">
                    From most secure to least:            </p>
                <ul className="text-left">
                    <li>
                        NSFileProtectionComplete - Only available when the device is unlocked and 10 seconds after locked.
                    </li>
                    <li>
                        NSFileProtectionCompleteUnlessOpen - Open files can be read/written after the device is locked.
                    </li>
                    <li>
                        NSFileProtectionCompleteUntilFirstUserAuthentication - This is the default and allows files to be read/written after the device is unlocked after a reboot.
                    </li>
                    <li>
                        NSFileProtectionNone - Although still encrypted, encryption keys would be available to an attacker and so provide minimal levels of security.                 </li>
                </ul>
                <p>
                    For sample code, see <a href="http://www.makebetterthings.com/iphone/ios-app-security-data-protection-part-1/" rel="noreferrer" target="_blank">here</a>.
                </p>
                <h6 className="rex-font-main-black mt-5">Android</h6>
                <p className="mt-5 font-italic">
                    Screenshots
                </p>
                <p>
                    If this current application screen contains sensitive information, it may be possible for an attacker to view this information in two ways; firstly if the user takes and shares a screenshot (or if the screenshot is recovered by an attacker in another way) and if an attacker can view the screenshot shown by Android in the recent apps menu.                </p>
                <p>
                    To protect against this, it is possible to disable screenshots of our application by setting the <text is="webview" className="rex-article-code">FLAG_SECURE</text> window flag
                </p>
                <p className="mt-5">
                    It should be noted that there are some controls that may still display including:
                </p>
                <ul className="text-left">
                    <li>
                        AutoCompleteTextView
                    </li>
                    <li>
                        Spinner (both dropdown and dialog modes)
                    </li>
                    <li>
                        the overflow menu of the framework-supplied action bar
                    </li>
                    <li>
                        ShareActionProvider
                    </li>
                    <li>
                        Dialog and subclasses (e.g., AlertDialog)
                    </li>
                    <li>
                        Toast
                    </li>
                    <li>
                        PopupWindow
                    </li>
                    <li>
                        ListPopupWindow
                    </li>
                    <li>
                        PopupMenu
                    </li>
                    <li>
                        legacy context menus
                    </li>
                </ul>
                <p>
                    If sensitive information is displayed in these controls, the following library <a href="https://github.com/commonsguy/cwac-security/blob/master/docs/FLAGSECURE.md" rel="noreferrer" target="_blank">here</a> is recommended
                </p>
                <p>
                    Although this does provide some protection to a user, it should be acknowledged that a user can find other ways to leak the screen such as more advanced screen grabbing techniques (especially on a rooted device) or by using a camera to take a picture of the screen.
                </p>
                <p className="mt-5 font-italic">
                    Web Cache
                </p>
                <p>
                    As many apps use web services or embed web controls, it's likely sensitive information will be cachced when using the WebView control. Often, this can include passwords, credit card details, session tokens and more. Care should be taken to make sure caching is disabled or cached data is removed.                </p>
                <p>
                    The WebView control has a <text is="webview" className="rex-article-code">clearCache(boolean includeDiskFiles)</text> method that should be called periodically to make sure nothing sensitive is cached and available to an attacker. In <text is="webview" className="rex-article-code">ClearCache(boolean includeDiskFiles)</text>, the <text is="webview" className="rex-article-code">includeDiskFiles</text> should be true to ensure RAM and files are cleared.                </p>
                <p className="mt-5 font-italic">
                    Internal storage
                </p>
                <p className="mt-5">
                    Android provides a <text is="webview" className="rex-article-code">SharedPreferences</text> class to store simple key/value pairs and is placed within the application sandbox. Data stored within the sandbox could be available to attackers in the following scenarios:            </p>
                <ul className="text-left">
                    <li>
                        Malware is running as the same permission level as the application.
                    </li>
                    <li>
                        Malware is running with root privileges.
                    </li>
                    <li>
                        Application vulnerability granting access to the application sandbox.
                    </li>
                    <li>
                        Application has been backed up.
                    </li>
                    <li>
                        Files are created with global RW access.
                    </li>
                </ul>
                <p>
                    Because of this, it should be assumed that an attacker can access the data and files stored within the sandbox and so nothing sensitive should be stored. If that cannot be achieved, data should be encrypted and the key placed in the Android <text is="webview" className="rex-article-code">KeyStore</text> using the <text is="webview" className="rex-article-code">KeyStore</text> API. The key should protected with a with a user supplied PIN or passcode.                </p>
                <p>Code examples can be found <a href="https://developer.android.com/reference/java/security/KeyStore.html " rel="noreferrer" target="_blank">here</a> and <a href="https://developer.android.com/training/articles/keystore.html" rel="noreferrer" target="_blank">here</a>.</p>
            </div>
            <div className="mt-5 w-100 d-flex justify-content-between">
                <Link to="/learn/sec-mob-dev/03">{'<'} Part 3: Certificate Pinning</Link>
                <Link to="/learn/sec-mob-dev/05">Part 5: Binary Protections {'>'}</Link>
            </div>
        </div>
    );
}

export default SMD04;