import React from 'react';
import CTAButton from '../components/CTAButton';

const HowToUse = () => {
    return (
        <div className="d-flex flex-column w-100 justify-content-center align-items-center text-center py-3 mb-5">
            <div className="d-flex flex-column content justify-content-center align-items-center px-5">
                <div className="d-flex align-items-end flex-wrap mb-5">
                    <img className="mx-5 blog-person" src="/assets/people/Harriet.png" alt="8 bit drawing of Harriet" />
                    <div className="mt-5">
                        <h3 className="rex-font-color-odb rex-font-style-title rex-sub text-uppercase text-left">
                            How To Use the <br />REX web application
                        </h3>
                        <h5 className="rex-font-color-do rex-font-main-black text-uppercase text-left">
                            A walk through on how to upload <br />and view reports on your application.
                        </h5>
                        <p className="font-italic mb-0 text-left">Harriet Melesi, Developer</p>
                    </div>
                </div>
                <p className="rex-font-color-do py-3">
                    The REX web application is the quickest (and most colourful) way to start using REX. Go to the <a href="https://app.rexscan.com/login">login page</a> and use your username and password to sign in.
                </p>
                <p className="rex-font-color-do pt-3">
                    Not signed up yet? Start now.
                </p>
                <CTAButton cta="sign up now" externalLink to="https://app.rexscan.com/register" />
                <video autoPlay loop muted className="w-50 mx-3 my-5 rex-screenshot" src="../assets/mp4s/logon.mp4" alt="Video of logging on to REX" />
                <p className="rex-font-color-do pt-3">
                    Once logged in, you'll be taken to the uploading page. This is where you upload your APK to be scanned by REX. Either drag and drop your file into the space, or click to search your files.
                </p>
                <p className="rex-font-color-do pt-3">
                    Once selected, REX will automatically upload and queue your file for scanning. The scanning itself has started when you see the status 'Processing' either on this upload page or by the file on the reports page.
                    Scan time varies, but usually this takes just a few minutes.
                </p>

                <video autoPlay loop muted className="w-50 mx-3 my-5 rex-screenshot" src="../assets/mp4s/upload.mp4" alt="Gif of uploading page REX" />
                <p className="rex-font-color-do pt-3">
                    Navigating to the Reports page will show a list of all your scanned APKs so far, with the results saved to look back on later.
                    Here you can see a top line status of the scan, with the number of issues found and the date it was scanned by REX.
                </p>

                <p className="rex-font-color-do pt-3">
                    To view an individual report in more detail, click on the 'View Report' button to the right of each scan.
                </p>

                <video autoPlay loop muted className="w-50 mx-3 my-5 rex-screenshot" src="../assets/mp4s/reports.mp4" alt="Gif of reports page in REX" />
                <p className="rex-font-color-do pt-3">
                    Each scan produces a detailed report for the APK. On an individual report page you can see details about the scan, the APK file itself, along with of course the security issues found.
                    Issues are ranked by both severity (how serious is the vulnerability to the security of your application) and confidence (how sure REX is that the Vulnerability exists).
                </p>

                <p className="rex-font-color-do pt-3">
                    The issues table displays high level information about all the vulnerabilities found in your application, with a brief description. Issues can be viewed by clicking the 'View Details' button, or opened in a new tab using the adjacent button.
                </p>

                <p className="rex-font-color-do pt-3">
                    You can also use the 'Download Report' button at the top of the page to save the report to PDF locally.
                </p>

                <video autoPlay loop muted className="w-50 mx-3 my-5 rex-screenshot" src="../assets/mp4s/report.mp4" alt="Gif of a report in REX" />
                <p className="rex-font-color-do pt-3">
                    Individual issue pages allow you to view the vulnerability in your code in more detail, and suggest how to fix the issue to improve the security of your application.
                </p>

                <p className="rex-font-color-do pt-3">
                    Issue pages also include links to issue classifications, related information and references.
                </p>

                <video autoPlay loop muted className="w-50 mx-3 my-5 rex-screenshot" src="../assets/mp4s/issue.mp4" alt="Gif of an issue in REX" />
                <p className="rex-font-color-do py-3">
                    REX also includes a chat function that enables you to speak to one of the team about any issues you may be having in real time.
                </p>

                <video autoPlay loop muted className="w-50 mx-3 my-5 rex-screenshot" src="../assets/mp4s/chat.mp4" alt="Gif of using the chat function in REX" />
                <p className="rex-font-color-do pt-3">
                    Curious to see what vulnerabilities REX can uncover in your application? Sign up now to find out!
                </p>
                <CTAButton cta="sign up now" externalLink to="https://app.rexscan.com/register" />
            </div>
        </div>
    );
}

export default HowToUse;