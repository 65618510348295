import React, { useEffect } from 'react';

const Terms = () => {

  useEffect(() => {
    document.title = "REX | Terms & Conditions";
  });

  return (
    <div className='container text-left px-4 py-4'>
      <h2 className='pt-5 mt-5'>REX Terms and Conditions</h2>
      <div className='container px-0 py-5 mx-0 my-5'>
        <p align="left">
          <strong>1. Agreement</strong>
        </p>
        <p align="left">
          1.1 These REX Terms and Conditions of Supply together with the documents
          referred to in it ("Terms") constitute the terms and conditions on which
          Digital Interruption Ltd ("Licensor") will grant to any user ("Licensee") a
          Testing Licence to use the software comprising REX ("REX" or the
          "Software"), following acceptance of an order as detailed below.
        </p>
        <p align="left">
          The following expressly form part of the Terms:
        </p>
        <p align="left">
          - The REX Testing Licence Agreement;
        </p>
        <p align="left">
          - The General Terms and Conditions;
        </p>
        <p align="left">
          - The REX Privacy Notice.
        </p>
        <p align="left">
          <strong>2. Definitions</strong>
        </p>
        <p align="left">
          2.1 In this Agreement, except to the extent expressly provided otherwise:
        </p>
        <p align="left">
          "
          <strong>Agreement</strong>
          " means this agreement including any Schedules,
          and any amendments to this Agreement from time to time;
        </p>
        <p align="left">
          "
          <strong>Documentation</strong>
          " means the documentation for the Software
          produced by the Licensor and delivered or made available by the Licensor to the Licensee; "
          <strong>Effective Date</strong>
          " means the date of execution of this
          Agreement;
        </p>
        <p align="left">
          "
          <strong>Intellectual Property Rights</strong>
          " means all intellectual
          property rights wherever in the world, whether registrable or
          unregistrable, registered or unregistered, including any application or
          right of application for such rights (and these "intellectual property
          rights" include copyright and related rights, database rights, confidential
          information, trade secrets, know-how, business names, trade names, trade
          marks, service marks, passing off rights, unfair competition rights,
          patents, petty patents, utility models, semi-conductor topography rights and rights in designs);
        </p>
        <p align="left">
          "
          <strong>Software</strong>
          " means the software identified in Part 1 of
          Schedule 1 (Software Licence Particulars) in object code format;
        </p>
        <p align="left">
          "
          <strong>Term</strong>
          " means the term of this Agreement, commencing in
          accordance with Clause 3.1 and ending in accordance with Clause 3.2.
        </p>
        <p align="left">
          <strong>3. Term</strong>
        </p>
        <p align="left">
          3.1 This Agreement shall come into force upon the Effective Date.
        </p>
        <p align="left">
          3.2 This Agreement shall continue for the duration of the testing license,
          which is free for 12 months.
        </p>
        <p align="left">
          <strong>4. Licence</strong>
        </p>
        <p align="left">
          4.1 The Licensor hereby grants to the Licensee from the date of supply of
          the Software to the Licensee until the end of the Term a worldwide,
          non-exclusive licence to:
        </p>
        <p align="left">
          (a) use the Software (as defined in General Terms and Conditions) for
          personal use and/or internal business purposes only, subject to the limitations and prohibitions set out and referred to in this
          Clause 4.
        </p>
        <p align="left">
          4.2 The Licensee may not sub-licence and must not purport to sub-licence
          any rights granted under Clause 4.1.
        </p>
        <p align="left">
          4.3 The Software may only be used by the officers and employees of the
          Licensee.
        </p>
        <p align="left">
          4.4 Save to the extent expressly permitted by this Agreement or required by
          applicable law on a non-excludable basis, any licence granted under this
          Clause 4 shall be subject to the following prohibitions:
        </p>
        <p align="left">
          (a) the Licensee must not sell, resell, rent, lease, loan, supply, publish,
          copy, distribute or redistribute the Software;
        </p>
        <p align="left">
          (b) the Licensee must not alter, edit or adapt the Software;
        </p>
        <p align="left">
          (c) the Licensee must not decompile, de-obfuscate or reverse engineer, or
          attempt to decompile, de-obfuscate or reverse engineer, the Software;
        </p>
        <p align="left">
          (d) the Licensee must not to use (or allow to be used) the Software, the
          Documentation for any unlawful purposes, particularly as the Software
          contains functionality that can be used to expose security vulnerabilities
          in computer systems;
        </p>
        <p align="left">
          (e) the Licensee must not share credentials provided by the Licensor
          enabling the Licensee to log in to the Licensor's serve;
        </p>
        <p align="left">
          (f) the Licensee must not use the software to create any software which is
          substantially similar to the Software;
        </p>
        <p align="justify">
          (g) the Licensee must not engage in any activity, practice or conduct which
          would constitute an offence under sections 1, 2, or 6 of the Bribery Act
          2010, if such activity, practice or conduct had been carried out in the UK;
          and; and
        </p>
        <p align="left">
          (h) responsibility of the Licensor for any and all liability claims,
          actions, or causes of action, together with the legal costs of the Licensor
          in bringing the same, arising by reason of or in any way relating to the
          Licensee's actions or activities of its employees, agents, or contractors
          under the Testing License.
        </p>
        <p align="left">
          <strong>5. No assignment of Intellectual Property Rights</strong>
        </p>
        <p align="left">
          5.1 Nothing in this Agreement shall operate to assign or transfer any
          Intellectual Property Rights from the Licensor to the Licensee, or from the Licensee to the
          Licensor.
        </p>
        <p align="left">
          <strong>6. Publicity and Communication</strong>
        </p>
        <p align="left">
          6.1. The purpose of the Testing Licence if primarily to test the software
          and provide feedback to the Licensor. By entering into the Testing Licence
          the Licensee agrees that the Licensor may refer to the Licensee as one of
          its customers internally however separate consent will be sought before the
          Licensee may be referred to as a client in externally published media. Any
          additional disclosure by the Licensor with respect to the Licensee shall be
          subject to its prior written consent.
        </p>
        <p align="left">
          6.2. By entering into the Testing License, the Licensee consents that the
          Licensor may process the personal data that it collects from the Licensee
          in accordance with the Licensor's Privacy Notice. The Licensee is
          responsible for ensuring it has in place all relevant consents, permissions
          or rights to share any personal data with the Licensor for the Licensor's
          use in accordance with the Privacy Notice and these Terms. In particular,
          the Licensor may use information it holds about the Licensee or its
          designated contacts for the purposes of, inter alia, sending out renewal
          reminders, questionnaires in particular feedback requests.
        </p>
        <p align="left">
          6.3. Any questions, comments and requests regarding the Licensor's data
          processing practices may be addressed to {' '}
          <a href="mailto:rex@digitalinterruption.com">
            <u>rex@digitalinterruption.com</u>
          </a>
        </p>
        <p align="left">
          <strong>7. Warranties</strong>
        </p>
        <p align="left">
          7.1 The Licensor warrants to the Licensee that it has the legal right and
          authority to enter into this Agreement and to perform its obligations under
          this Agreement.
        </p>
        <p align="left">
          7.2 The Licensor warrants to the Licensee that the Software, when used by
          the Licensee in accordance with this Agreement, will not breach any laws,
          statutes or regulations applicable under English law.
        </p>
        <p align="justify">
          7.3 The Licensor warrants to the Licensee that the Software, when used by
          the Licensee in accordance with this Agreement, will not infringe the
          Intellectual Property Rights of any person in any jurisdiction and under
          any applicable law.
        </p>
        <p align="left">
          7.4 All of the parties' warranties and representations in respect of the
          subject matter of this Agreement are expressly set out in this Agreement.
          To the maximum extent permitted by applicable law, no other warranties or
          representations concerning the subject matter of this Agreement will be
          implied into this Agreement or any related contract.
        </p>
        <strong>8. Acknowledgements and warranty limitations</strong>
        <p align="left">
          8.1 The Licensee acknowledges that complex software is never wholly free
          from defects, errors and bugs; and subject to the other provisions of this
          Agreement, the Licensor gives no warranty or representation that the
          Software will be wholly free from defects, errors and bugs.
        </p>
        <p align="left">
          8.2 The Licensee acknowledges that complex software is never entirely free
          from security vulnerabilities; and subject to the other provisions of this
          Agreement, the Licensor gives no warranty or representation that the
          Software will be entirely secure.
        </p>
        <p align="left">
          8.3 The Licensee acknowledges that the Software is only designed to be
          compatible with that software specified as compatible in the Software
          Specification; and the Licensor does not warrant or represent that the
          Software will be compatible with any other software.
        </p>
        <p align="left">
          8.4 The Licensee acknowledges that the Licensor will not provide any legal,
          financial, accountancy or taxation advice under this Agreement or in
          relation to the Software; and, except to the extent expressly provided
          otherwise in this Agreement, the Licensor does not warrant or represent
          that the Software or the use of the Software by the Licensee will not give
          rise to any legal liability on the part of the Licensee or any other
          person.
        </p>
        <p align="left">
          <strong>9. Limitations and exclusions of liability</strong>
        </p>
        <p align="left">
          9.1 Nothing in this Agreement will:
        </p>
        <p align="left">
          (a) limit or exclude any liability for death or personal injury resulting
          from negligence;
        </p>
        <p align="left">
          (b) limit or exclude any liability for fraud or fraudulent
          misrepresentation;
        </p>
        <p align="left">
          (c) limit any liabilities in any way that is not permitted under applicable
          law; or
        </p>
        <p align="left">
          (d) exclude any liabilities that may not be excluded under applicable law.
        </p>
        <p align="left">
          9.2 The limitations and exclusions of liability set out in this Clause 9
          and elsewhere in this Agreement:
        </p>
        <p align="left">
          (a) are subject to Clause 9.1; and
        </p>
        <p align="left">
          (b) govern all liabilities arising under this Agreement or relating to the
          subject matter of this Agreement, including liabilities arising in contract, in
          tort (including negligence) and for breach of statutory duty, except to the
          extent expressly provided otherwise in this Agreement.
        </p>
        <p align="left">
          9.3 The Licensor shall not be liable to the Licensee in respect of any loss
          of profits or anticipated savings.
        </p>
        <p align="left">
          9.4 The Licensor shall not be liable to the Licensee in respect of any loss
          of revenue or income.
        </p>
        <p align="left">
          9.5 The Licensor shall not be liable to the Licensee in respect of any loss
          of use or production.
        </p>
        <p align="left">
          9.6 The Licensor shall not be liable to the Licensee in respect of any loss
          of business, contracts or opportunities.
        </p>
        <p align="left">
          9.7 The Licensor shall not be liable to the Licensee in respect of any loss
          or corruption of any data, database or software.
        </p>
        <p align="left">
          9.8 The Licensor shall not be liable to the Licensee in respect of any
          special, indirect or consequential loss or damage.
        </p>
        <p align="left">
          <strong>10. Termination</strong>
        </p>
        <p align="left">
          10.1 The Licensee may terminate this Agreement by giving to the Licensor a
          written notice of termination to
          <a href="mailto:rex@digitalinterruption.com">
            <u>rex@digitalinterruption.com</u>
          </a>
        </p>
        <p align="left">
          10.2 Either party may terminate this Agreement immediately by giving
          written notice of termination to the other party if:
        </p>
        <p align="left">
          (a) the other party commits any breach of this Agreement.
        </p>
        <p align="left">
          10.3 Either party may terminate this Agreement immediately by giving
          written notice of termination to the other party if:
        </p>
        <p align="left">
          (a) the other party:
        </p>
        <p align="left">
          (i) is dissolved;
        </p>
        <p align="left">
          (ii) ceases to conduct all (or substantially all) of its business; (iii) is
          or becomes unable to pay its debts as they fall due;
        </p>
        <p align="left">
          (iv) is or becomes insolvent or is declared insolvent; or
        </p>
        <p align="left">
          (v) convenes a meeting or makes or proposes to make any arrangement or
          composition with its creditors;
        </p>
        <p align="left">
          (b) an administrator, administrative receiver, liquidator, receiver,
          trustee, manager or similar is appointed over any of the assets of the
          other party; or
        </p>
        <p align="left">
          (c) an order is made for the winding up of the other party, or the other
          party passes a resolution for its winding up.
        </p>
        <p align="left">
          <strong>11. Effects of termination</strong>
        </p>
        <p align="left">
          11.1 Upon the termination of this Agreement, all of the provisions of this
          Agreement shall cease to have effect, save that the following provisions of
          this Agreement shall survive and continue to have effect (in accordance
          with their express terms or otherwise indefinitely).
        </p>
        <p align="left">
          11.2 Except to the extent that this Agreement expressly provides otherwise,
          the termination of this Agreement shall not affect the accrued rights of
          either party.
        </p>
        <p align="left">
          11.3 For the avoidance of doubt, the licenses of the Software in this
          Agreement shall terminate upon the termination of this Agreement; and,
          accordingly, the Licensee must immediately cease to use the Software upon
          the termination of this Agreement.
        </p>
        <p align="left">
          <strong>12. General</strong>
        </p>
        <p align="left">
          12.1 No breach of any provision of this Agreement shall be waived except
          with the express written consent of the party not in breach.
        </p>
        <p align="left">
          12.2 If any provision of this Agreement is determined by any court or other
          competent authority to be unlawful and/or unenforceable, the other
          provisions of this Agreement will continue in effect. If any unlawful
          and/or unenforceable provision would be lawful or enforceable if part of it
          were deleted, that part will be deemed to be deleted, and the rest of the
          provision will continue in effect (unless that would contradict the clear
          intention of the parties, in which case the entirety of the relevant
          provision will be deemed to be deleted).
        </p>
        <p align="left">
          12.3 This Agreement is made for the benefit of the parties, and is not
          intended to benefit any third party or be enforceable by any third party.
          The rights of the parties to terminate, rescind, or agree any amendment,
          waiver, variation or settlement under or relating to this Agreement are not
          subject to the consent of any third party.
        </p>
        <p align="left">
          12.4 Subject to Clause 9.1, this Agreement shall constitute the entire
          agreement between the parties in relation to the subject matter of this
          Agreement, and shall supersede all previous agreements, arrangements and
          understandings between the parties in respect of that subject matter.
        </p>
        <p align="left">
          12.5 This Agreement shall be governed by and construed in accordance with
          English law.
        </p>
        <p align="left">
          12.6 The courts of England shall have exclusive jurisdiction to adjudicate
          any dispute arising under or in connection with this Agreement.
        </p>

      </div>
    </div>
  );
};

export default Terms;
