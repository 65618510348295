import React from 'react';
import CTAButton from './CTAButton';

const HomepageTwo = () => {
    return (
        <div>

<div className="d-flex justify-content-center">
                <div className="content container d-flex flex-wrap-reverse justify-content-center align-items-center">
                    <div className='text-left px-5 my-5 half-container'>
                        <h2 className="rex-font-color-odb rex-font-style-title rex-sub my-0 rex-skinny-line">SCAN &amp; FIX</h2>
                        <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black">VULNERABILITIES IN YOUR CODE</h5>
                        <p className="rex-font-color-do">REX scans for over 130 known security issues and is designed specifically for Android app developers and software testers. Upload an application and REX will return a list of detected vulnerabilities in your code with suggested fixes.</p>
                        <CTAButton cta="find out more" to="/learn/rex-web-how-to"/>
                    </div>
                    <img className="w-100 mx-3 my-5 half-container rex-screenshot mx-3" src="assets/screenshots/homescreen.png" alt="Screenshot of inside REX app" />
                </div>
            </div>

    </div>
    );
}

export default HomepageTwo;