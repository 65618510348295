import React from 'react';
import CTAButton from './CTAButton';

const HomepageEight = () => {
    return (
    <div className="d-flex justify-content-center">
        <div className='content container d-flex flex-wrap-reverse justify-content-center align-items-center'>
        <div className='text-left px-5 my-5 half-container'>
            <h2 className="rex-font-color-odb rex-font-style-title rex-sub my-0 rex-skinny-line">SIMPLE &amp; USER-FRIENDLY</h2>
            <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black">SECURITY BAKED IN</h5>
            <p className="rex-font-color-do">With REX security by design is as easy as uploading an APK, or integrating continuous scanning into your pipeline via our simple API. Results are clearly laid out, security issues are explained, and example code fixes are recommended, embedding security into every stage of your development process.</p>
            <CTAButton cta="learn more" to="/docs"/>
        </div>
        <video autoPlay muted className="w-100 mx-3 my-5 half-container rex-screenshot mx-3" src="/assets/mp4s/upload.mp4" alt="Gif demonstrating REX API" />
        </div>
    </div>
    );
}

export default HomepageEight;