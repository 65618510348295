import React from 'react';
import { Link } from '@reach/router';

const SMD05 = () => {
    return (
        <div>
            <div className="d-flex align-items-end flex-wrap mb-5">
                <img className="mx-5 blog-person" src="/assets/people/Jahmel.png" alt="8 bit drawing of Jay" />
                <div className="mt-5">
                    <h3 className="rex-font-color-odb rex-font-style-title rex-sub text-uppercase">
                        Secure Mobile Application <br />Development
                    </h3>
                    <h5 className="rex-font-color-do rex-font-main-black text-uppercase">
                        Part 5: Binary Protections
                    </h5>
                    <p className="font-italic mb-0">Jahmel Harris, Technical Director</p>
                </div>
            </div>
            <p className="font-italic mt-5">Attack Scenario: 2, 3</p>
            <p className="">
                Appropriate binary protections should be present. A binary protection is a client side security control which will attempt to stop an attacker from reverse engineering or modifying the application. With binary protections, it is important to realise that with enough time and effort an attacker will be able to bypass any control. With appropriate controls, however, the level of skill needed to understand the application will increase. By stacking binary controls, the level of difficulty rises in relation to how many controls are implemented. This can often make it un-worthwhile for an attacker to target the protected application.
            </p>
            <p>
                An application that's weak against this type of threat could be vulnerable to the following types of attack:
            </p>
            <ul className="text-left">
                <li>
                    Spoofing e.g. by changing authentication tokens.
                </li>
                <li>
                    Code modification, including DRM bypasses.
                </li>
                <li>
                    Information disclosure of sensitive information including encryption keys or proprietary algorithms.
                </li>
                <li>
                    Reputational risk, where malware can be inserted into a once legitimate application.
                </li>
                <li>
                    Vulnerability research.
                </li>
                <li>
                    Security control bypass.
                </li>
            </ul>

            <p className="mt-5">
                Many of the security controls in this section require constant improvement as attackers find ways to understand and bypass these client side controls.                </p>

            <div className="mt-5 w-100 d-flex justify-content-between">
                <Link to="/learn/sec-mob-dev/04">{'<'} Part 4: Data Storage</Link>
                <Link to="/learn/sec-mob-dev/06">Part 6: Obfuscation {'>'}</Link>
            </div>
        </div>
    );
}

export default SMD05;