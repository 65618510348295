import React from 'react';
import { Link } from '@reach/router';

const SMD02 = () => {
    return (
        <div>
            <div className="d-flex align-items-end flex-wrap mb-5">
                <img className="mx-5 blog-person" src="/assets/people/Jahmel.png" alt="8 bit drawing of Jay" />
                <div className="mt-5">
                    <h3 className="rex-font-color-odb rex-font-style-title rex-sub text-uppercase">
                        Secure Mobile Application <br />Development
                    </h3>
                    <h5 className="rex-font-color-do rex-font-main-black text-uppercase">
                        Part 2: Transport Layer Security
                    </h5>
                    <p className="font-italic mb-0">Jahmel Harris, Technical Director</p>
                </div>
            </div>
            <p className="font-italic mt-5">Attack Scenario: 2, 3</p>
            <p>
                When developing a mobile application, we should put in effort to ensure that the network layer is secure and is therefore not susceptible to eavesdropping. This should be true whether the application connects to the Internet via wifi or through the mobile network. Because of this, care should be taken to make sure all communication is performed over an encrypted channel. Although it could be argued that only sensitive information should be encrypted, due to the risk of accidentally classifying data incorrectly or a vulnerability existing that be exploited by injecting traffic into unencrypted communications, we recommend all network traffic is be encrypted and TLS employed where possible.
            </p>
            <p className="mb-5">
                This principle should be applied to all communications including bluetooth and NFC where possible.
            </p>
            <p>
                Consider the following when using TLS:
            </p>
            <ul className="text-left">
                <li>
                    All traffic should be encrypted. This includes traffic from third party libraries.
                </li>
                <li>
                    Correct cipher strength should be used and weak ciphers should be disabled.
                </li>
                <li>
                    TLS 1.2 should be used and everything weaker should be disabled.
                </li>
                <li>
                    Certificates should be validated correctly and not weakened for testing purposes.
                </li>
                <li>
                    Self signed certificates should not be accepted unless certificate pinning is in use.
                </li>
                <li>
                    For particularly sensitive information, additional encryption should be applied on data before sending over TLS.
                </li>
                <li>
                    Data sent over secure connections should not be logged.
                </li>
            </ul>
            <h6 className="rex-font-main-black mt-5">iOS</h6>
            <p>
                App Transport Security was introduced in iOS 9 and forces applications to use TLSv1.2. This should not be weakened unnecessarily. For cases where this is unavoidable, it should be a vulnerability that is risk accepted and only done on a whitelist basis. Wildcards should not be used and nothing weaker than TLS 1.2 should be accepted.
            </p>
            <h6 className="rex-font-main-black mt-5">Android</h6>

            <p>
                Newer version of Android (API {'>'} 24) support Network Security Configuration, allowing us to easily opt out of cleartext traffic. This can be done by specifying <text is="webview" className="rex-article-code">cleartextTrafficPermitted="false"</text> in the <text is="webview" className="rex-article-code">network_security_config.xml</text> file. Use this with caution though as there are scenarios (such as using the <text is="webview" className="rex-article-code">java.net.Socket</text> API) which may permit unencrypted HTTP traffic.
            </p>

            <div className="mt-5 w-100 d-flex justify-content-between">
                <Link to="/learn/sec-mob-dev/01">{'<'} Part 1: Introduction &amp; security grading.</Link>
                <Link to="/learn/sec-mob-dev/03">Part 3: Certificate Pinning {'>'}</Link>
            </div>
        </div>
    );
}

export default SMD02;