import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

const CTAButton = ({cta, to, externalLink}) => {
    if(externalLink) {
        return (
            <a className="rex-button" href={to} target='blank'>
                {cta.toUpperCase()}
            </a>
        )
    } else {
        return (
            <Link className="rex-button" to={to}>
                {cta.toUpperCase()}
            </Link>
        )
    }
};

CTAButton.propTypes = {
    cta: PropTypes.string,
    to: PropTypes.string,
    external: PropTypes.bool,
  };

export default CTAButton;