import { Link } from '@reach/router';
import React, { useEffect } from 'react';
import RexBanner from '../components/RexBanner';

const Docs = () => {

    useEffect(() => {
        document.title = "REX | Documentation";
    });

    return (
        <div>
            <RexBanner text="DOCS" subtext="DOCUMENTATION, GUIDES &amp; WALKTHROUGHS" />
            <div className='d-flex flex-column align-items-center my-5 px-4 mx-0'>
                <div className="content row">
                    <div className="text-left col-sm-2 d-none d-sm-block">
                        <div className="rex-sticky-sidebar">
                            <a className="my-1" href='#create'>Creating an account</a>
                            <a className="my-1" href='#getting-started'>Getting started</a>
                            <a className="my-1" href='#web-app'>Web Application</a>
                            <a className="my-1" href='#api'>API</a>
                            <a className="my-1" href='#jenkins'>Jenkins plug-in</a>
                            <a className="my-1" href='#reports'>Reports</a>
                            <a className="my-1" href='#issues'>Issues</a>
                            <a className="my-1" href='#settings'>Settings</a>
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-start container col-sm-10 article-content">
                        <div className="anchor" id='create' />
                        <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Creating an account</h2>
                        <p>
                            To create an account, <a href="https://app.rexscan.com/register">register</a> using your email and a username and password.
                        </p>
                        <p>You will then receive a verification e-mail with a link, which you will need to follow in order to complete your account setup.</p>
                        <p>Once verified you will be able to login, and choose your plan. We currently have four subscription plans to choose from:</p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Plan</th>
                                    <th scope="col">Monthly cost*</th>
                                    <th scope="col">Monthly scanning time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Micro</th>
                                    <td>FREE</td>
                                    <td>30 minutes**</td>
                                </tr>
                                <tr>
                                    <th scope="row">Mini</th>
                                    <td>£15</td>
                                    <td>5 hours</td>
                                </tr>
                                <tr>
                                    <th scope="row">Midi</th>
                                    <td>£200</td>
                                    <td>100 hours</td>
                                </tr>
                                <tr>
                                    <th scope="row">Maxi</th>
                                    <td>£1000</td>
                                    <td>1000 hours</td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="small mb-0">*Plans are billed every 30 days.</p>
                        <p className="small">**Free minutes are capped across all users of REX.</p>
                        <p>If you have needs which are not met by one of these plans, please <Link to="/contact">get in touch</Link></p>
                        <div className="anchor" id='getting-started' />
                        <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Getting started</h2>
                        <p>
                            There are multiple ways to use REX and you are free to use any depending on your needs and current setup:
                        </p>
                        <ul>
                            <li>
                                <a className='rex-link-pink' href='#web-app'>
                                    Web application
                                </a>
                            </li>
                            <li>
                                <a className='rex-link-pink' href='#api'>
                                    API
                                </a>
                            </li>
                            <li>
                                <a className='rex-link-pink' href='#jenkins'>
                                    Jenkins plug-in
                                </a>
                            </li>
                        </ul>
                        <p>
                            If you don't have an APK to use, or want to test it out with an example APK first, feel free to use one of the examples below:
                        </p>
                        <ul>
                            <li>
                                <a className='rex-link-pink' href='https://file.digitalinterruption.com/InsecureBankv2.apk' target='blank'>
                                    Insecure Banking App
                                </a>
                            </li>
                            <li>
                                <a className='rex-link-pink' href='https://file.digitalinterruption.com/Vul_BroadcastReceiver.apk' target='blank'>
                                    Vulnerable App
                                </a>
                            </li>
                        </ul>
                        <div className="anchor" id='web-app' />
                        <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Web Application</h2>
                        <p>Using the web frontend for REX is the easiest way to perform a scan.</p>
                        <h5 className='rex-font-style-main rex-font-main-black text-uppercase rex-font-color-do'>Log in</h5>
                        <p>Log into REX at <a href='https://app.rexscan.com/login' target='blank'>here</a> using your username and password. If you have forgotten your password please contact us at rex@digitalinterruption.com </p>
                        <h5 className='rex-font-style-main rex-font-main-black text-uppercase rex-font-color-do'>Dashboard</h5>
                        <p>Once you’ve logged in, you’ll be presented with your dashboard.</p>
                        <h5 className='rex-font-style-main rex-font-main-black text-uppercase rex-font-color-do'>UPLOAD</h5>
                        <p>This is where you can upload an application to scan.</p>

                        <p>Either click on the upload area and navigate to the APK you want to scan from your files, or drag the APK directly into the area. Once selected, the scan will run automatically. When the scan is completed, the results are displayed.</p>

                        <p>As well as viewing the results in the web app, a PDF can be downloaded making it easier to share findings. This can be done by clicking Download Report from the individual report page.</p>
                        <h5 className='rex-font-style-main rex-font-main-black text-uppercase rex-font-color-do'>Live chat</h5>
                        <p>REX has a live chat feature which can be used for questions or feedback at any time. This is generally monitored live on weekdays 9:30-17:30 BST, but any requests can be submitted here and we will get back to you as long as contact details are provided.</p>
                        <div className="anchor" id='api' />
                        <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">API</h2>
                        <p>Docs for using REX via the API can be found <a href='https://app.rexscan.com/api/v1/' target='blank'>here</a>.</p>
                        <div className="anchor" id='jenkins' />
                        <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Jenkins plug-in</h2>
                        <p>Coming soon...</p>
                        <div className="anchor" id='reports' />
                        <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Reports</h2>
                        <p>
                            A report is available for each job (an individual scan for an APK). This will be shown after a scan is run, and all reports are saved in the Reports tab within REX once logged in to your account.                    </p>
                        <p><b>Date created: </b>The date the job was created on REX.</p>
                        <p><b>Job ID: </b>The unique job identifier.</p>
                        <p><b>Name: </b>The file name of the APK that was submitted for analysis.</p>
                        <p><b>Status: </b>The current status of the job, one of:</p>
                        <ul>
                            <li>
                                Queued
                            </li>
                            <li>
                                Processing
                            </li>
                            <li>
                                Complete
                            </li>
                            <li>
                                Complete With Errors
                            </li>
                            <li>
                                Failed
                            </li>
                        </ul>
                        <p><b>Issues: </b>The number of potential security issues found from the scan.</p>
                        <p><b>Time taken: </b>The time taken to complete the scan.</p>
                        <p><b>Size: </b>The size of the APK file uploaded into REX.</p>
                        <p><b>MD5: </b>The MD5 checksum of the APK that was submitted for analysis.</p>
                        <p><b>SHA1: </b>The SHA-1 checksum of the APK that was submitted for analysis.</p>
                        <p><b>SHA256: </b>The SHA-256 checksum of the APK that was submitted for analysis.</p>
                        <div className="anchor" id='issues' />
                        <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Issues</h2>
                        <p className='lead'>Severity</p>
                        <p>
                            Severity is the risk of the vulnerability, and issues are ranked on a scale of 1-20 with 1 being the most critical, and 20 being the least.</p>
                        <ul>
                            <li>
                                Critical: 1-4 – high risk vulnerability, should be fixed immediately
                            </li>
                            <li>
                                High: 5-9
                            </li>
                            <li>
                                Medium: 10-14
                            </li>
                            <li>
                                Low: 15-19
                            </li>
                            <li>
                                Informational: 20 – bad practice, but not posing any immediate security risk
                            </li>
                        </ul>
                        <p className='lead'>Confidence</p>
                        <p>
                            Confidence is how sure REX is that the vulnerability exists. Unfortunately no scanner is completely free from false positives.</p>
                        <ul>
                            <li>
                                High: 1 - There is no doubt the report is correct.
                            </li>
                            <li>
                                Medium : 2 - The report is correct, but its impact may not be as severe as described.
                            </li>
                            <li>
                                Low: 3 - The tests have flagged the issue, but it may be a false-positive.
                            </li>
                        </ul>
                        <p className='lead'>Types</p>
                        <p>
                            Issues have an overview, or summary on the main report page. By clicking 'View Details' a user can see a more detailed description of the issue, along with examples of vulnerable and safe code in order to make changes to their own code.
                        </p>
                        <p><b>CVSSv3 Vector: </b>CVSSv3 stands for <a href='https://www.first.org/cvss/v3-0/' target='blank'>Common Vulnerability Scoring System version 3.0</a>. The Common Vulnerability Scoring System (CVSS) provides a way to capture the principal characteristics of a vulnerability, and produce a numerical score reflecting its severity, as well as a textual representation of that score. The numerical score can then be translated into a qualitative representation (such as low, medium, high, and critical). REX users can click on the CVSSv3 Vector to learn more about this score.</p>
                        <p><b>Classification: </b>This is the <a href='https://cwe.mitre.org/index.html' target='blank'>Common Weakness Enumeration</a> type - CWE is a community-developed list of software and hardware weakness types. Clicking on this classification will take a user to the CWE page for that type.</p>
                        <div className="anchor" id='settings' />
                        <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">Settings</h2>
                        <p className='lead'>Change password</p>
                        <p>
                            Use the form to change your REX account password.
                        </p>
                        <p className='lead'>API Key</p>
                        <p>
                            Use your API key for REX API calls. Regenerate API key as needed.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Docs;