import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

const ContentBlock = ({link, blurb, title, picture, pictureAlt}) => {
    if(picture) {
        return (
            <div className="content-block my-5 d-flex flex-wrap">
                <div className="content-block-image mr-5 mb-5">
                <img className="w-100 rex-screenshot" alt={pictureAlt} src={picture} />
                </div>
                <div className="content-block-text">
                    <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black">{title}</h5>
                    <p className="rex-font-style-main">{blurb}</p>
                    <Link to={link}>read more</Link>
                </div>
            </div>
            );
    }
    return (
    <div className="content-block my-5">
            <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black">{title}</h5>
            <p className="rex-font-style-main">{blurb}</p>
            <Link to={link}>read more</Link>
    </div>
    );
}

ContentBlock.propTypes = {
    link: PropTypes.string, 
    blurb: PropTypes.string, 
    title: PropTypes.string, 
    picture: PropTypes.string, 
    pictureAlt: PropTypes.string
}

ContentBlock.defaultProps = {
    link: '', 
    blurb: '', 
    title: '', 
    picture: '', 
    pictureAlt: ''
}

export default ContentBlock;