import React from 'react';
import CTAButton from './CTAButton';

const HomepageFive = () => {
    return (
        <div className="d-flex justify-content-center rex-bg-second align-items-stretch">
        <div className="d-flex flex-column justify-content-between w-100 flex-shrink-1">
            <div className="top-step-filler"/>
            <div className="bottom-step-filler"/>
        </div>
        <div className="outer-content w-100">
            <div className="w-100 d-flex align-items-start">
                <div className="middle-top-step-filler flex-grow-1"/>
                <div className="skinny-step-rtl-small"/>
            </div>
            <div className="d-flex justify-content-center py-0">
        <div className='content container d-flex flex-wrap justify-content-center align-items-center py-3'>
            <img className="half-container pt-0 w-100" src="assets/REX-Prices.png" alt="Graph using dinosaurs to show difference in cost of fixing vulnerabilities at different stages of software development."/>
        <div className='text-left px-5 my-5 half-container'>
            <h2 className="rex-font-color-offwhite rex-font-style-title rex-sub my-0 rex-skinny-line">FLEXIBLE PRICING</h2>
            <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black">SECURITY FOR EVERY BUDGET</h5>
            <p className="rex-font-color-do">
                REX has a range of monthly subscription costs to fit your budget; from free plans for lower usage customers, to tailored solutions for larger scale companies. Our prices are simple and completely transparent, so there will never be an unexpected cost.
            </p>
            <CTAButton cta="see prices" to="/pricing"/>
        </div>
        </div>
        </div>   
            <div className="skinny-step-ltr-small w-100"/>
        </div> 
        <div className="d-flex flex-column justify-content-between w-100 flex-shrink-1">
            <div className="top-step-filler"/>
            <div className="bottom-step-filler"/>
        </div>
    </div>

    );
}

export default HomepageFive;