import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";

const client = new ApolloClient({
  uri:
    "https://api-eu-central-1.graphcms.com/v2/cko32h6jccrd301z11py8ao95/master",
});


const AppComponent = () => <App />;

ReactDOM.render(
  <ApolloProvider client={client}>
      <AppComponent />
  </ApolloProvider>,
  document.getElementById("root")
);


serviceWorker.unregister();
