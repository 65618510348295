import React from 'react';
import { Link } from '@reach/router';

const SMD09 = () => {
    return (
        <div>
            <div className="d-flex align-items-end flex-wrap mb-5">
                <img className="mx-5 blog-person" src="/assets/people/Jahmel.png" alt="8 bit drawing of Jay" />
                <div className="mt-5">
                    <h3 className="rex-font-color-odb rex-font-style-title rex-sub text-uppercase">
                        Secure Mobile Application <br />Development
                    </h3>
                    <h5 className="rex-font-color-do rex-font-main-black text-uppercase">
                        Part 9: Hook Detection
                    </h5>
                    <p className="font-italic mb-0">Jahmel Harris, Technical Director</p>
                </div>
            </div>
            <p className="mt-5">
                An attacker will often look to hook an application in order to modify its behaviour at runtime.  This can be to remove security controls (for example, certificate pinning or root/jailbreak detection), to recover secrets such as encryption keys, to log encrypted messages pre-encryption or to make use of application functions such as to lock/unlock methods. Often, attackers will add logging statements into the code in order to understand execution flow or trace method invocations.            </p>

            <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                <div className="rex-flowchart-div-good">
                    ReadData
                </div>
                <i className="fas fa-arrow-right"></i>
                <div className="rex-flowchart-div-good">
                    EncryptData
                </div>
                <i className="fas fa-arrow-right"></i>
                <div className="rex-flowchart-div-good">
                    SendData
                </div>
            </div>
            <p className="font-italic text-center">
                Figure 1 - Normal Execution
            </p>
            <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                <div className="rex-flowchart-div-good">
                    ReadData
                </div>
                <i className="fas fa-arrow-right"></i>
                <div className="rex-flowchart-div-bad">
                    LogData
                </div>
                <i className="fas fa-arrow-right"></i>
                <div className="rex-flowchart-div-good">
                    EncryptData
                </div>
                <div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <i className="fas fa-arrow-right"></i>
                        <div className="rex-flowchart-div-bad">
                            SendData
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <i className="fas fa-times"></i>
                        <div className="rex-flowchart-div-good">
                            SendData
                        </div>
                    </div>
                </div>


            </div>
            <p className="font-italic text-center">
                Figure 2 - Hooking Method
            </p>
            <p className="mt-5">
                Figure 1 and Figure 2 show how an attacker can insert functions, or change functions to malicious ones by hooking the application.
            </p>
            <p>
                Like with jailbreak and root detection, as this is a control that exists entirely on a device controlled by an attacker, we should assume that with enough effort this control can be bypassed. The goal is to understand the risk profile of our application and apply sufficient protections to be inline with this profile.
            </p>
            <p>
                As this control does exist client side, it is likely that a tool will eventually exist that will disable the control and so novel approaches should be implemented to make it more difficult for these tools to work without considerable effort from an attacker.
            </p>
            <h6 className="rex-font-main-black mt-5">iOS</h6>
            <p className="mt-5">
                iOS provides no standard way of detecting whether an application is being hooked, however there are some techniques that can be used:
            </p>
            <ul className="text-left">
                <li>
                    Examining the modules and image names of libraries loaded in memory.
                </li>
                <li>
                    Checking the memory addresses of methods.
                </li>
                <li>
                    Looking for the presence of trampoline addresses or other signatures in memory.
                </li>
            </ul>
            <p className="mt-5">
                Sample code for these checks can be found <a href="https://labs.nettitude.com/blog/ios-and-android-runtime-and-anti-debugging-protections/" rel="noreferrer" target="_blank">here</a>.
            </p>
            <h6 className="rex-font-main-black mt-5">Android</h6>
            <p className="mt-5">
                Android provides no standard way of detecting whether an application is being hooked, however there are some techniques that can be used:
            </p>
            <ul className="text-left">
                <li>
                    Looking for common hooking frameworks such as de.robv.android.xposed.installer and com.saurik.substrate.
                </li>
                <li>
                    Checking pathnames in /proc/{`{pid}`}/map for suspicious libraries such as frida-agent-32.so, libsubstrate.so and XposedBridge.jar.
                </li>
                <li>
                    Looking for the presence of native functions introduced by the hooking frameworks.
                </li>
                <li>
                    Examining the stacktrace for classes used by hooking frameworks.
                </li>
            </ul>
            <p className="mt-5">
                Sample code for these checks can be found <a href="https://labs.nettitude.com/blog/ios-and-android-runtime-and-anti-debugging-protections/" rel="noreferrer" target="_blank">here</a>.
            </p>
            <div className="mt-5 w-100 d-flex justify-content-between">
                <Link to="/learn/sec-mob-dev/08">{'<'} Part 8: Debug Protection</Link>
                <Link to="/learn/sec-mob-dev/10">Part 10: Runtime Integrity Checks {'>'}</Link>
            </div>
        </div>
    );
}

export default SMD09;