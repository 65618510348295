import React from 'react';
import { Link } from '@reach/router';

const SMD06 = () => {
    return (
        <div>
            <div className="d-flex align-items-end flex-wrap mb-5">
                <img className="mx-5 blog-person" src="/assets/people/Jahmel.png" alt="8 bit drawing of Jay" />
                <div className="mt-5">
                    <h3 className="rex-font-color-odb rex-font-style-title rex-sub text-uppercase">
                        Secure Mobile Application <br />Development
                    </h3>
                    <h5 className="rex-font-color-do rex-font-main-black text-uppercase">
                        Part 6: Obfuscation
                    </h5>
                    <p className="font-italic mb-0">Jahmel Harris, Technical Director</p>
                </div>
            </div>
            <p className="my-5">
                Binary obfuscation is one technique often used to keep any client side implementation secret and is particularly useful for applications dealing with DRM or mobile payments. Binary obfuscation is the practice of transforming a binary into a version that has the same functionality as the original but with steps to make the binary more complex and therefore more difficult to reverse engineer. On some platforms (such as Android) when obfuscation is not applied, it is often possible to recover a highly accurate version of the source code.            </p>
            <p>
                The following are techniques commonly used in obfuscation:
            </p>
            <ul className="text-left">
                <li>
                    String Encryption: String constants are replaced with encrypted versions and run through a decryption function before use.
                </li>
                <li>
                    Symbol Renaming: Symbols (such as method and variable names) are renamed to remove meaning and to make it more difficult to follow method calls.
                </li>
                <li>
                    Code Flow Obfuscation: Code structures are removed, flattened or merged.
                </li>
                <li>
                    Dummy Code Insertion: Code is inserted that has no effect on the program, but is executed.
                </li>
                <li>
                    Instruction Substitution: Common instructions are substituted for instructions that have the same effect,but are less obvious. Simple instructions can be expanded to many instructions.
                </li>
            </ul>
            <p className="mt-5">
                There are many more techniques which can make it more difficult to recover source code or perform static analysis on a binary.
            </p>
            <p className="mt-5">
                Several tools exist which provide this functionality including:
            </p>
            <ul className="text-left">
                <li>
                    Morpher
                </li>
                <li>
                    Metaforic
                </li>
                <li>
                    Arxan
                </li>
                <li>
                    LLVM Obfuscator
                </li>
            </ul>

            <div className="mt-5 w-100 d-flex justify-content-between">
                <Link to="/learn/sec-mob-dev/05">{'<'} Part 5: Binary Protections</Link>
                <Link to="/learn/sec-mob-dev/07">Part 7: Root/Jailbreak Detection {'>'}</Link>
            </div>
        </div>
    );
}

export default SMD06;