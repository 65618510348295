import React from 'react';
import CTAButton from './CTAButton';


const HomeBanner = () => {
    return (
    <div className="rex-bg-light d-flex justify-content-center mb-4">
        <div className='d-flex flex-row w-100 flex-wrap-reverse align-items-start justify-content-left content'>
            <div className="half-container">
            <img src='assets/rex-head-only-aw.png' className="rex-hero-image" alt='REX logo'/>
            </div>
            <div className='text-left rex-hero-text-container half-container-fixed mb-1'>

                <h1 className="rex-font-style-title rex-largest rex-font-color-white rex-skinny-line my-0">SECURITY <br></br>IS EVOLVING</h1>
                <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black">REX HUNTS FOR VULNERABILITIES</h5>
                <div className="d-flex align-items-center mb-4">

                <img src='assets/and.png' alt='Android logo' className="android-logo"/>
                <p className="rex-font-color-do rex-font-style-main mb-0 pb-0">Upload your Android app directly into REX or use the API to build security testing into your pipeline.</p>
                </div>
                <CTAButton cta="sign up now" externalLink to="https://app.rexscan.com/register"/>
            </div>
        </div>
    </div>
    );
}

export default HomeBanner;