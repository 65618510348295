import React from 'react';
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { people } from '../constants/peopleConfig';
import Loading from './Loading';
import Error from './Error';

const ReactMarkdown = require("react-markdown");

const GET_BLOG_DETAIL = gql`
  query blogPostByTitle($ID: ID!) {
    blogPosts(where: { id: $ID }) {
      title
      subtitle
      author
      createdAt
      content {
        markdown
      }
    }
  }
`;

const BlogPostContent = ({blog_id}) => {

    React.useEffect(() => {

    })

    const getAuthorImages= (array) => {
        const authorImages = <div className="d-flex pr-3">
            {array.map(author => {
                return <img className="blog-person" src={`/assets/people/${author}.png`} alt={`cartoon of ${author}`}/>
            })}
        </div>;
        return authorImages;
    }


    const getAuthorDetails = (array) => {
        const authorList = <ul className="list-unstyled mb-0">
            {array.map(author => {
                return <li className="font-italic mb-0">{people[author].title}</li>
            })}
        </ul>;
        return authorList;
    }

    return (
        <Query query={GET_BLOG_DETAIL} variables={{ ID: blog_id }}>
            {({ loading, error, data }) => {
                if (loading) return <Loading/>;
                if (error || data.blogPosts === null) return <Error/>;

                const xmlString = data.blogPosts.content.markdown;

                return(
                            <div className="d-flex align-items-end flex-wrap mt-5 align-self-start">
                                <div>

                                {getAuthorImages(data.blogPosts.author)}
                                </div>
                                <div className="pt-1">
                                    <h3 className="rex-font-color-odb rex-font-style-title rex-sub text-uppercase">
                                        {data.blogPosts.title}
                                    </h3>
                                    <h5 className="rex-font-color-do rex-font-main-black text-uppercase">
                                        {data.blogPosts.subtitle}
                                    </h5>
                                    {getAuthorDetails(data.blogPosts.author)}  
                                </div>

                            <div className="my-5 backend-content w-100"> 
                            {<ReactMarkdown children={xmlString} />}
                        </div>
                        </div>
                )
            }}
        </Query>
    )
}

export default BlogPostContent;