import React, { useEffect } from 'react';
import RexBanner from '../components/RexBanner';
import { Router, Redirect } from '@reach/router';
import SecMobDev from '../screens/SecMobDev';
import HowToUse from '../content/HowToUse';
import LearnHome from '../components/LearnHome';

const Content = () => {

    useEffect(() => {
        document.title = "REX | Learn";
    });

    return (
        <div>
            <RexBanner text="LEARN" subtext="HOW TO USE REX AND WRITE SECURE CODE"/>
            <Router>
                <HowToUse path="/rex-web-how-to" />
                <SecMobDev path="/sec-mob-dev/*"/>
                <LearnHome path="/" />
                <Redirect from='*' to='/learn' noThrow/>
            </Router>     
        </div>
    );
};

export default Content;