import React from 'react';
import CTAButton from './CTAButton';

const HomepageSix = () => {
    return (
    <div className="d-flex justify-content-center">
        <div className='content container d-flex flex-wrap-reverse justify-content-center align-items-center'>
        <div className='text-left px-5 my-5 half-container'>
            <h2 className="rex-font-color-odb rex-font-style-title rex-sub my-0 rex-skinny-line">DETECT EARLY</h2>
            <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black">FIX ISSUES BEFORE DEPLOYMENT</h5>
            <p className="rex-font-color-do">With REX you can detect security weaknesses early and often as your apps are being developed. Upload your Android app directly into the REX web application as you code, or use the API to build security testing into your pipeline. Build customer confidence by releasing secure applications.</p>
            <CTAButton cta="sign up now" externalLink to="https://app.rexscan.com/register"/>
        </div>
        <img className="w-100 mx-3 my-5 half-container rex-screenshot mx-3" src="assets/screenshots/screenshot.png" alt="Screenshot of inside REX app" />
        </div>
    </div>
    );
}

export default HomepageSix;