import React, { useEffect } from 'react';
import RexBanner from '../components/RexBanner';
import CTAButton from '../components/CTAButton';

const PricePage = () => {

    useEffect(() => {
        document.title = "REX | Pricing";
    });

    return (
        <div>
            <RexBanner text='PRICING' subtext='FIND A SOLUTION FOR YOUR BUDGET' />
            <div className='d-flex flex-column align-items-center my-5 px-4 mx-0'>
                <div className="d-flex flex-column justify-content-center align-items-start container">
                    <h2 className="rex-font-color-odb rex-font-style-title text-uppercase mt-2">plans</h2>
                    <p>We currently have four subscription plans to choose from:</p>
                    <table className="rex-table">
                        <thead>
                            <tr>
                                <th scope="col">Plan</th>
                                <th scope="col">Monthly cost*</th>
                                <th scope="col">Monthly scanning time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Micro</th>
                                <td>FREE</td>
                                <td>30 minutes**</td>
                            </tr>
                            <tr>
                                <th scope="row">Mini</th>
                                <td>£15</td>
                                <td>5 hours</td>
                            </tr>
                            <tr>
                                <th scope="row">Midi</th>
                                <td>£200</td>
                                <td>100 hours</td>
                            </tr>
                            <tr>
                                <th scope="row">Maxi</th>
                                <td>£1000</td>
                                <td>1000 hours</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="small mb-0">*Plans are billed every 30 days.</p>
                    <p className="small">**Free minutes are capped across all users of REX.</p>

                    <p>No credit card details are required for the free plan (Micro).</p>
                    <div className="w-100 mb-5 mt-1 text-center">
                        <CTAButton cta="sign up now" externalLink to="https://app.rexscan.com/register" />
                    </div>

                    <p>Paid plans are charged monthly. Should you wish to cancel, you can do this through your Stripe account (the link is provided in your REX dashboard). Your REX account will still be active on your paid plan until the next billing date. When this comes around, you can choose a new plan.</p>
                    <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black text-uppercase mt-2">extra minutes</h5>
                    <p className="font-italic">(coming soon)</p>
                    <p>
                        Used up all the minutes on your paid plan? Just got one more quick scan to do? Soon you will be able to pay for additional minutes instead of having to move up to a more epxensive plan.
                    </p>
                    <p>At REX we recognise that your scanning time may vary by month, and that you don't need to pay for 100 hours when all you need is 6. That's why we'll be introducing this structure so that everyone will pay for just what they need.</p>
                    <p>Additional minutes will not be available on the free plan.</p>
                    <p>See the table below for the pricing structure of these additional minutes. As you can see, there's no premium applied to this extra time, just the same rate you pay per hour in your plan:</p>

                    <table className="rex-table">
                        <thead>
                            <tr>
                                <th scope="col">Plan</th>
                                <th scope="col">Monthly scanning time included</th>
                                <th scope="col">Cost per additional hour</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Micro</th>
                                <td>30 minutes</td>
                                <td>N/A</td>
                            </tr>
                            <tr>
                                <th scope="row">Mini</th>
                                <td>5 hours</td>
                                <td>£3</td>
                            </tr>
                            <tr>
                                <th scope="row">Midi</th>
                                <td>100 hours</td>
                                <td>£2</td>
                            </tr>
                            <tr>
                                <th scope="row">Maxi</th>
                                <td>1000 hours</td>
                                <td>£1</td>
                            </tr>
                        </tbody>
                    </table>

                    <h5 className="rex-font-color-do rex-font-style-main rex-font-main-black text-uppercase mt-4">contact us</h5>
                    <p>Looking for something else? Want to talk to us about a bespoke solution to your company&apos;s needs? Fill in the form below and we will get back to you. </p>
                    <iframe title="contact us form" className="plans-contact-form" src="https://share.hsforms.com/1EIBqjkTkTuaOw00IsocYPg4tnut" />
                </div>
            </div>
        </div>
    );
};

export default PricePage;